import React, { useState } from "react";
import './AITag.scss'
import classNames from "classnames";
import { TagsType, approveOneAITag, generateOneAITag, removeOneAITag, updateOneAITagTag } from "entities/files";
import useClickOutside from "services/useClickOutside";
import { AITagEditMenu } from "../EditMenu/EditMenu";

interface AITagProps {
    tag: string,
    fileId: string,
    tagType: TagsType,
    index?: number,
}


function AITag({ tag, fileId, tagType, index }: AITagProps) {
    const [isSelected, setIsSelected] = useState<boolean>(false)
    const tagRef = useClickOutside(() => {
        setIsSelected(false);
    });

    const handleApproveAItag = (id: string, fieldName: TagsType, value: string, index: number | undefined) => {
        approveOneAITag({ id, fieldName, value, index: index })
    }

    const handleRemoveAITag = (id: string, fieldName: TagsType, index: number | undefined) => {
        removeOneAITag({ id, fieldName, index: index })
    }

    const handleCopyAITag = (tagToCopy: string | undefined) => {
        if (tagToCopy) navigator.clipboard.writeText(tagToCopy)
    }

    const handleRetryAITag = (id: string, fieldName: TagsType, index: number | undefined) => {
        generateOneAITag({ id, fieldName, index: index })
    }

    const handlePasteAITag = async (fileId: string, tagType: TagsType, index?: number) => {
        try {
            const text = await navigator.clipboard.readText();
            if (text.trim() !== '') {
                updateOneAITagTag({ id: fileId, fieldName: tagType, value: text, index: index })
                navigator.clipboard.writeText('')
            }
        } catch (error) {
            console.error('Clipboard Error', error);
        }
    }
    return (
        <div className={classNames({
            'ai-tag': true,
            'ai-tag-selected': isSelected,
            'file_row-tag-hovered': !isSelected,
        })}
            ref={tagRef}
            onClick={() => setIsSelected(true)}>
            {isSelected &&
                <AITagEditMenu
                    onApproveClick={() => handleApproveAItag(fileId, tagType, tag, index)}
                    onCopyClick={() => handleCopyAITag(tag)}
                    onPasteClick={() => handlePasteAITag(fileId, tagType, index)}
                    onRetryClick={() => handleRetryAITag(fileId, tagType, index)}
                    onRemoveClick={() => handleRemoveAITag(fileId, tagType, index)}
                />
            }
            <p>{tag}</p>
            <div className="ai-tag-approve-icon"
                onClick={() => handleApproveAItag(fileId, tagType, tag, index)} />
            <div className="file_row-tag_remove-icon"
                onClick={() => handleRemoveAITag(fileId, tagType, index)} />

        </div>
    )
}

export default AITag