import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import useClickOutside from '../../services/useClickOutside';
import PreviewUnit from './PreviewUnit';
import Textarea from './Textarea';
import { $AIMode, $email, $files, $selectedFileID, $userId, FileData, TagsI, TagsType, addFiletoFavourites, approveAllFileAITags, generateFileAITags } from '../../entities';
import TagSelect from 'components/FilesTable/TagSelect';
import TagInput from './TagInput';
import AITagInput from './AITagInput';
import config from 'config';
import './Preview.scss';

const imageExtensions = ['.png', '.webp', '.jpg', '.jpeg', '.gif'];

export default function Preview() {
    const userId = useStore($userId);
    const email = useStore($email);
    const previewRef = useRef<HTMLDivElement>(null);
    const refLeft = useRef<HTMLDivElement>(null);
    const [isUserSettingsOpen, setIsUserSettigsOpen] = useState<boolean>(false);
    const userSettingsRef = useClickOutside(() => setIsUserSettigsOpen(false));
    const [isFileSettingsOpen, setIsFileSettigsOpen] = useState<boolean>(false);
    const fileSettingsRef = useClickOutside(() => setIsFileSettigsOpen(false));
    const [isFavourite, setIsFavourite] = useState<boolean>(false);
    const [file, setFile] = useState<FileData | null>(null);
    const selectedFileID = useStore($selectedFileID);
    const { activeFiles: files, favoritedFilesID } = useStore($files);

    useEffect(() => {
        if (selectedFileID) {
            const foundFile = files.find((file) => file.filename === selectedFileID);
            if (foundFile !== undefined) {
                setFile(foundFile);
                setIsFavourite(favoritedFilesID.includes(foundFile.filename));
            }
        }
    }, [selectedFileID, files, favoritedFilesID]);

    useEffect(() => {
        const resizerLeft = refLeft.current;

        if (previewRef && previewRef.current && resizerLeft) {
            const resizeablePreview = previewRef.current;
            const previewStyles = window.getComputedStyle(resizeablePreview);
            let width = parseInt(previewStyles.width, 10);
            let x = 0;

            const onMouseMoveLeftResize = (event: MouseEvent) => {
                const dx = event.clientX - x;
                x = event.clientX;
                width = width - dx;
                resizeablePreview.style.width = `${width}px`;
            };

            const onMouseUpLeftResize = () => document.removeEventListener("mousemove", onMouseMoveLeftResize);

            const onMouseDownLeftResize = (event: MouseEvent) => {
                x = event.clientX;
                resizeablePreview.style.right = previewStyles.right;
                document.addEventListener("mousemove", onMouseMoveLeftResize);
                document.addEventListener("mouseup", onMouseUpLeftResize);
            };

            resizerLeft.addEventListener("mousedown", onMouseDownLeftResize);

            return () => {
                resizerLeft.removeEventListener("mousedown", onMouseDownLeftResize);
            };
        }
    });

    return (
        <div className='preview__container' ref={previewRef}>
            <div className='resizer_preview' ref={refLeft}>
                <div className='resizer-l' />
            </div>
            <div className='preview_header'>
                <div className='preview_header-profile_info'>
                    <div className='preview_header-right_part'>
                        <div className='preview_header-avatar' />
                        <p className='preview_text'>Studioname</p>
                    </div>
                    <div className='preview_header-left_part'>
                        <p className='preview_text'>{email}</p>
                        <div className='preview_header-settings'
                            ref={userSettingsRef}
                            onClick={() => setIsUserSettigsOpen((prev) => !prev)}>
                            <div className="preview_header-settings_menu"
                                style={{ display: isUserSettingsOpen ? 'flex' : 'none' }}>
                                <div className="preview_header-settings_menu-item">
                                    <div className='settings-user_icon' />
                                    Settings
                                </div>
                                <div className="preview_header-settings_menu-item">
                                    <div className='settings-logout_icon' />
                                    Logout</div>
                            </div>
                        </div>
                    </div>
                </div>
                {file && imageExtensions.includes(file.extension) &&
                    <div className='preview_header-image'>
                        <img src={`${config.minioUrl}/backup/${userId}/${file.filename}`} alt={file.extension} />
                    </div>}
            </div>
            {file ? (
                <div className='preview'>
                    <div className='preview-item'>
                        <p className='preview-file_name'>{file.originalname}</p>
                        <div className='preview-item-icons'>
                            <div className={classNames({ 'preview-item-fav_icon': true, 'preview-item-fav_icon_active': isFavourite })}
                                onClick={() => addFiletoFavourites(file.filename)} />
                            <div className='preview-item-settings_icon'
                                ref={fileSettingsRef}
                                onClick={() => setIsFileSettigsOpen((prev) => !prev)}>
                                <div className="preview_header-settings_menu"
                                    style={{ display: isFileSettingsOpen ? 'flex' : 'none' }}>
                                    <div className="preview_header-settings_menu-item">Delete</div>
                                    <div className="preview_header-settings_menu-item">Something</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PreviewDetailsUnit file={file} />
                    <PreviewNoteUnit />
                    <PreviewTagsUnit AItags={file.AItags} tags={file.tags} fileId={file.filename} />
                    <div className='preview-parameters'>
                        <p className=''>Parameters</p>
                        <button className='preview-parameters-bttn'>
                            <div className='preview-parameters-bttn-icon' />
                            <p>Add parameter</p>
                        </button>
                    </div>
                </div>
            ) : (
                <div className='no_file'>
                    <p>Select file to see it's properties</p>
                </div >
            )}
        </div >
    );
}


function PreviewDetailsUnit({ file }: { file: FileData }) {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const acsessDropdownRef = useClickOutside(() => setIsMenuOpen(false));

    return (
        <PreviewUnit initialState={true} name='Details'>
            <div className='preview-item'>
                <p className='preview-item-title'>Modified</p>
                <p className='preview-item-value'>{file.date}</p>
            </div>
            <div className='preview-item'>
                <p className='preview-item-title'>Size</p>
                <p className='preview-item-value'>{file.size}</p>
            </div>
            <div className='preview-item'>
                <p className='preview-item-title'>Kind</p>
                <p className='preview-item-value'>{file.extension}</p>
            </div>
            <div className='preview-item'>
                <p className='preview-item-title'>access</p>
                <div className='preview-item-access'>
                    {file.access.slice(0, 3).map((participant, index) => (
                        <div className="preview-item-access-image" key={index}>
                            <img src={participant.avatar} alt=" " />
                        </div>
                    ))}
                    <div
                        className={`dropdown-arrow ${isMenuOpen && 'open'}`}
                        ref={acsessDropdownRef}
                        onClick={() => setIsMenuOpen((prev) => !prev)}>
                    </div>
                </div>
            </div>
        </PreviewUnit>
    );
}

function PreviewNoteUnit() {
    const [note, setNote] = useState<string>('');

    return (
        <PreviewUnit initialState={false} name='Note'>
            <Textarea value={note} onChange={(e) => setNote(e.target.value)} />
        </PreviewUnit>
    );
}

function PreviewTagsUnit({ AItags, tags, fileId }: { AItags: TagsI | undefined, tags: TagsI, fileId: string }) {
    const AIMode = useStore($AIMode);

    return (
        <PreviewUnit initialState={true} name='Tags'>
            {AIMode && AItags &&
                <div className='preview-ai-tags-controller'>
                    <div className='ai-tags-controller-item' onClick={() => generateFileAITags(fileId)} >
                        <div className='preview-ai-tags-retry-icon' />
                        <p>Retry</p>
                    </div>
                    <div className='ai-tags-controller-item-separator' />
                    <div className='ai-tags-controller-item' onClick={() => approveAllFileAITags(fileId)}>
                        <div className='preview-ai-tags-approve-icon' />
                        <p>Approve</p>
                    </div>
                </div>}

            <p className='preview-item-title'>Category</p>
            {AItags && AItags.category ?
                (<AITagInput tag={AItags.category} key={`ai_input-${AItags.category}`} fileId={fileId} tagType={TagsType.CATEGORY} />) :
                (<TagSelect fileId={fileId} tag={tags.category} placeholder={"Select category"} type={'preview'} />)
            }

            <p className='preview-item-title'>Object</p>
            {AItags && AItags.object ?
                (<AITagInput tag={AItags.object} key={`ai_input-${AItags.object}`} fileId={fileId} tagType={TagsType.OBJECT} />) :
                (<TagInput fileId={fileId} tag={tags.object} tagType={TagsType.OBJECT} />)
            }

            <p className='preview-item-title'>Subject</p>
            {(tags.subject && tags.subject?.length !== 0) ? (
                tags.subject.map((subjectTag, index) => (
                    <TagInput fileId={fileId} tag={subjectTag} tagType={TagsType.SUBJECT} key={`input-${subjectTag}-${index}`} index={index} />
                ))
            ) : ((!AItags?.subject || AItags?.subject.length === 0) && <TagInput fileId={fileId} tagType={TagsType.SUBJECT} />)}
            {AIMode && AItags && AItags.subject && AItags.subject.length !== 0 &&
                AItags.subject.map((item, index) => (
                    <AITagInput tag={item} key={`ai_input-${item}-${index}`} fileId={fileId} tagType={TagsType.SUBJECT} index={index} />
                ))
            }

            <p className='preview-item-title'>Program</p>
            {AItags && AItags.program ?
                (<AITagInput tag={AItags.program} key={`ai_input-${AItags.program}`} fileId={fileId} tagType={TagsType.PROGRAM} />) :
                (<TagInput fileId={fileId} tag={tags.program} tagType={TagsType.PROGRAM} />)}


            <p className='preview-item-title'>Application</p>
            {(tags.application && tags.application.length !== 0) ? (
                tags.application.map((applicationTag, index) => (
                    <TagInput fileId={fileId} tag={applicationTag} tagType={TagsType.APPLICATION} index={index} key={`input-${applicationTag}-${index}`} />
                ))
            ) :
                ((!AItags?.application || AItags?.application.length === 0) && <TagInput fileId={fileId} tagType={TagsType.APPLICATION} />)}
            {AIMode && AItags && AItags.application && AItags.application.length !== 0 &&
                AItags.application.map((item, index) => (
                    <AITagInput tag={item} key={`ai_input-${item}-${index}`} fileId={fileId} tagType={TagsType.APPLICATION} index={index} />
                ))
            }
        </PreviewUnit>
    );
}
