import React from "react";
import './AITagInput.scss'
import { TagsType, approveOneAITag, generateOneAITag, removeOneAITag } from "entities/files";


interface AITagInputProps {
    tag: string,
    fileId: string,
    tagType: TagsType,
    index?: number,
}



function AITagInput({ tag, fileId, tagType, index }: AITagInputProps) {
    return (
        <div className="ai_input">
            <p>{tag}</p>
            <div className="ai_input-icons">
                <div className="ai_input-approve-icon"
                    onClick={() => approveOneAITag({ id: fileId, fieldName: tagType, value: tag, index: index })} />
                <div className="ai_input-retry-icon"
                onClick={()=>generateOneAITag({ id: fileId, fieldName: tagType, index: index })}/>
                <div className="ai_input-remove-icon" 
                onClick={()=>removeOneAITag({ id: fileId, fieldName: tagType, index: index })}/>
            </div>
        </div>
    )
}

export default AITagInput