import { createEvent, createStore } from "effector";
import { AccesI } from "./files";
import MockFoldersArr from "../data/folderData.json";

export interface FolderData {
  id: string;
  subFoldersIDs: string[] | null;
  name: string;
  date: string;
  size: string;
  acess: AccesI[];
  isOpen: boolean;
  isActive: boolean;
  [key: string]: boolean | string | string[] | AccesI[] | null | undefined;
}

export const $treeFolderStore = createStore<FolderData[]>(initFolder());

export const setIsOpen = createEvent<string>();

export const $activeFolderID = createStore<string | null>(null);
export const setActiveFolderID = createEvent<string | null>();

$activeFolderID.on(setActiveFolderID, (_, value) => {
  // if(value){
  //   console.log('setActiveFolderID')
  //   window.history.pushState(null, "", `storage/${value}`);
  // }
  return value;
});

$treeFolderStore.on(setIsOpen, (state, id) => {
  const folders = state.map((folder) => {
    if (folder.id !== id) return folder;
    return {
      ...folder,
      isOpen: !folder.isOpen,
    };
  });

  return folders;
});

function initFolder(): FolderData[] {
  return MockFoldersArr;
}
