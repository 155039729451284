import React, { useState } from "react";
import './TagSelect.scss'
// import { group } from "console";
import useClickOutside from "../../../services/useClickOutside";
import { TagsType, updateTag } from "entities/files";
import classNames from "classnames";


export const categoriesMock = [
    {
        title: 'CAD',
        items: ['3d model', 'bim', 'Material', 'Raster images', 'hdri', 'Cut-outs', 'Vector graphics', 'Icons', 'Fonts', 'Brushes']
    },
    {
        title: 'Text',
        items: ['Regulations', 'Fiction', 'Monograph', 'Technical literature', 'Tech sheet', 'Pricelist', 'Guidebook']
    },
    {
        title: 'Images',
        items: ['Photo', 'Collage', 'Rendering', 'Model', 'Drawing', 'Reference', 'Precedents']
    },
    {
        title: 'Product',
        items: ['bim', 'Material', '3d model', 'Raster images',]
    },
]

interface TagSelectProps {
    fileId: string,
    tag: string | null,
    type?: 'tag' | 'preview',
    placeholder?: string
}
function TagSelect({ fileId, tag, type = 'tag', placeholder = 'Select' }: TagSelectProps) {
    // const [selected, setSelected] = useState<string | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const dropdownRef = useClickOutside(() => {
        setIsMenuOpen(false);
    });

    return (
        <div
            className={classNames({
                [type + "_select"]: true,
                [type + "_select-open"]: isMenuOpen,

            })}
            ref={dropdownRef}
            onClick={() => setIsMenuOpen((prev) => !prev)}>
            {tag ? <p>{tag}</p> : <p className="select_placeholder">{placeholder}</p>}
            <div className="tag_select-arrow" />
            <div
                className={classNames({
                    [type + "_select-menu"]: true,
                    [type + "_select-menu"]: isMenuOpen,

                })}
                style={{ display: isMenuOpen ? 'flex' : 'none' }}>
                {categoriesMock.map((category, index) => (
                    <div className="select_menu-group" key={`${type}-categoryGroup-${index}`}>
                        <p className="select_menu-group_title">{category.title}</p>
                        {category.items.map((categoryItem, itemIndex) => (
                        
                            <div className={`select_menu-group_item ${tag===categoryItem ? 'active' : ''}`}
                                key={`${type}-category-${index}-${itemIndex}`}
                                onClick={() => updateTag({ id: fileId, fieldName: TagsType.CATEGORY, value: categoryItem })}>
                                <p>{categoryItem}</p>
                                {tag===categoryItem && <div className="select_menu-group_item-icon"/>}
                            </div>
                        ))}

                    </div>
                ))}
            </div>

        </div>
    )
}

export default TagSelect