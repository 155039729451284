
import { useStore } from 'effector-react';

// import { $filter, Filter as IFilter, selectFilter } from '../../../entities';
import './Filter.scss';
import { $activeFilters, $treeFilters, FilterI, setIsFilterActive, setIsFilterOpen } from '../../../entities/filters';
import { TagsType } from 'entities/files';


export default function Filter() {

    const treeFilters = useStore($treeFilters)
    const activeFilters = useStore($activeFilters)

    const renderFilters = (children: FilterI[], type?: TagsType,) => {
        return (
            <div className='filters_wrapper'>
                {children.map((filter, index) => (
                    <div className='filter'
                    key={`filter-${filter.id}-${index}`}>
                        <div className='filter__info'>
                            {type && [TagsType.CATEGORY, TagsType.SUBJECT, TagsType.APPLICATION].includes(type) ? ' ' :
                                filter.children.length !== 0 ?
                                <div className={`subfilter__arrow ${filter.isOpen && 'open'}`} 
                                    // <div className={filter.isOpen ? 'subfilter__arrow' : 'filter__plus_icon'}
                                        onClick={() => setIsFilterOpen(filter.id)} /> :
                                    <div className='filter_no_icon' />
                            }

                            <div onClick={() => setIsFilterActive(filter.id)}>
                                {type && type === TagsType.SUBJECT ? (
                                    <div className='filter_checkbox-square'>
                                        {filter.isActive && <div className='filter_checkbox-square-active' />}
                                    </div>) : (
                                    <div className='filter_checkbox'>
                                        {filter.isActive && <div className='filter_checkbox_active' />}
                                    </div>
                                )}
                            </div>



                            <p className='filter__title' onClick={() => setIsFilterActive(filter.id)}>{filter.name}</p>
                        </div>
                        {(filter.children.length !== 0 && filter.isOpen) && renderFilters(filter.children)}
                    </div>
                ))
                }
            </div>
        )
    }

    return (
        <div className="filters">
            <p className='filter_name'>Filter by tags</p>
            <div className='filters_folder'>
                {treeFilters.children.map((rootFilter, index) => (
                    <div key={`rootFilter-${rootFilter.id}`}>
                        <div className='root_filter'
                            onClick={() => setIsFilterOpen(rootFilter.id)}>
                            <div className='root_filter_right'>
                                <div className={`filter_arrow ${rootFilter.isOpen && 'open'}`} />
                                <p>{rootFilter.name}</p>
                            </div>
                            {rootFilter.isOpen && <div className='filter__minus_icon' />}

                        </div>
                        {rootFilter.isOpen &&
                            <div className='root_filter-subfolders'>
                                {activeFilters &&
                                    <div className='selected_filters-wrapper'>
                                        {activeFilters.map((active) => (
                                            active.type === rootFilter.type &&
                                            <div className='selected_filter' key={`active-${active.id}`}>
                                                {active.name}
                                                <div className='selected_filter-remove_icon' onClick={() => setIsFilterActive(active.id)} />
                                            </div>
                                        ))}
                                    </div >}
                                {renderFilters(rootFilter.children, rootFilter.type)}
                            </div>
                        }
                    </div>
                ))}

            </div >
        </div>
    );
}
