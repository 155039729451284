

export const mockAItags = [
  {
    type: "CATEGORY",
    items: [
      "3d model",
      "bim",
      "Material",
      "Raster images",
      "hdri",
      "Cut-outs",
      "Vector graphics",
      "Icons",
      "Fonts",
      "Brushes",
      "Regulations",
      "Fiction",
      "Monograph",
      "Technical literature",
      "Tech sheet",
      "Pricelist",
      "Guidebook",
    ],
  },
  {
    type: "OBJECT",
    items: [
      "terrazzo",
      "brick",
      "door handle",
      "dining table",
      "faucet",
      "chair",
      "windows",
      "acoustic panel",
    ],
  },
  {
    type: "SUBJECT",
    items: [
      "architecture",
      "design",
      "plumbing",
      "acoustic",
      "interior design",
      "cg",
      "lighting",
      "landscape design",
      "decor",
    ],
  },
  {
    type: "APPLICATION",
    items: [
      "load-bearing walls",
      "sanitary",
      "wall",
      "exterior",
      "ceiling",
      "lavatory",
      "kitchen",
      "bedroom",
    ],
  },
  {
    type: "PROGRAM",
    items: ["civil", "residential", "public", "commercial"],
  },
];

// export let mockFilesData: FileData[] = [
//   {
//     id: "1",
//     folderId: "0",
//     preview: require("./img/file1.jpg"),
//     name: "pupupu.tiff",
//     extension: "tiff",
//     date: "Jan 32,22:00",
//     size: "7.6MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: {
//       category: "font",
//       object: "terrazzo",
//       subject: ["architecture", "design"],
//       program: "civil",
//       application: null,
//     },
//   },
//   {
//     id: "2",
//     folderId: "0",
//     preview: require("./img/file2.jpg"),
//     name: "downloads(1).jpg",
//     extension: "jpg",
//     date: "Jan 32,22:00",
//     size: "2.4MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: {
//       category: "product",
//       object: "brick",
//       subject: ["structural design"],
//       program: null,
//       application: ["load-bearing walls"],
//     },
//   },
//   {
//     id: "3",
//     folderId: "0",
//     preview: require("./img/file3.jpg"),
//     name: "qwerty.jpg",
//     extension: "jpg",
//     date: "Jan 32,22:00",
//     size: "1.2MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: {
//       category: "product",
//       object: "dining table",
//       subject: ["dining table", "design"],
//       program: "residential",
//       application: ["interior"],
//     },
//   },
//   {
//     id: "4",
//     folderId: "0",
//     preview: require("./img/file4.jpg"),
//     name: "downloads(18).jpg",
//     extension: "jpg",
//     date: "Jan 20,12:00",
//     size: "1.1MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "5",
//     folderId: "0",
//     preview: require("./img/file5.jpg"),
//     name: "downloads(18).pdf",
//     extension: "jpg",
//     date: "Jan 20,17:32",
//     size: "7.6MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "6",
//     folderId: "0",
//     preview: require("./img/file6.jpg"),
//     name: "result-hay-chair.rvt",
//     extension: "rvt",
//     date: "Jan 20,19:00",
//     size: "10.3MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "7",
//     folderId: "0",
//     preview: require("./img/file4.jpg"),
//     name: "downloads(18).jpg",
//     extension: "jpg",
//     date: "Jan 21,21:45",
//     size: "5.5MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "8",
//     folderId: "0",
//     preview: require("./img/file2.jpg"),
//     name: "downloads(15).jpg",
//     extension: "jpg",
//     date: "Jan 30,14:13",
//     size: "4.3MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "9",
//     folderId: "0",
//     preview: require("./img/file3.jpg"),
//     name: "file.jpg",
//     extension: "jpg",
//     date: "Jan 30,14:14",
//     size: "2.1MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "10",
//     folderId: "0",
//     preview: require("./img/file1.jpg"),
//     name: "blabla.tiff",
//     extension: "tiff",
//     date: "Jan 30,19:00",
//     size: "8.9MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "11",
//     folderId: "0",
//     preview: require("./img/file6.jpg"),
//     name: "download(11).tiff",
//     extension: "jpg",
//     date: "Jan 31,22:00",
//     size: "1.6MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "12",
//     folderId: "0",
//     preview: require("./img/file4.jpg"),
//     name: "download(11).pdf",
//     extension: "pdf",
//     date: "Jan 32,21:20",
//     size: "5.7MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },

//   {
//     id: "13",
//     folderId: "01",
//     preview: require("./img/file1.jpg"),
//     name: "blabla.tiff",
//     extension: "tiff",
//     date: "Jan 30,19:00",
//     size: "8.9MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "14",
//     folderId: "01",
//     preview: require("./img/file6.jpg"),
//     name: "download(11).tiff",
//     extension: "jpg",
//     date: "Jan 31,22:00",
//     size: "1.6MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
//   {
//     id: "15",
//     folderId: "01",
//     preview: require("./img/file4.jpg"),
//     name: "download(11).pdf",
//     extension: "pdf",
//     date: "Jan 32,21:20",
//     size: "5.7MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },

//   {
//     id: "16",
//     folderId: "01",
//     preview: require("./img/file4.jpg"),
//     name: "downloads(18).jpg",
//     extension: "jpg",
//     date: "Jan 20,12:00",
//     size: "1.1MB",
//     acess: defaultAcces,
//     note: " ",
//     tags: defaultTags,
//   },
// ];

// export const mockFiles: Files = {
//   files: mockFilesData,
//   selectedFilesID: [],
//   favoritedFilesID: [],
// };
