import { createEffect, createStore, sample } from 'effector';
import { exist } from 'services/user';
import config from 'config';

enum LocalStorage {
    USER = 'USER',
}

export const $email = createStore<string>(config.guestAccount.email);
export const $userId = createStore<string>(config.guestAccount.id);
export const $guest = createStore(getUserFiledFromLS('id') === config.guestAccount.id);

export const existFx = createEffect(exist);

$guest
    .on($userId, (_, value) => value === config.guestAccount.id);

sample({
    clock: [existFx.doneData, existFx.failData],
    filter: payload => payload !== true,
    fn: () => {
        document.cookie = `${LocalStorage.USER}=; Domain=quarters-dev.site; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        return true;
    },
    target: $guest,
});

sample({
    clock: existFx.doneData,
    filter: payload => payload === true,
    fn: () => getUserFiledFromLS('email'),
    target: $email,
});

sample({
    clock: existFx.doneData,
    filter: payload => payload === true,
    fn: () => getUserFiledFromLS('id'),
    target: $userId,
});

function getUserFiledFromLS(field: 'email' | 'id') {
    const user = document.cookie.replace(/(?:(?:^|.*;\s*)USER\s*=\s*([^;]*).*$)|^.*$/, "$1");
    if (!user) return config.guestAccount[field];
    return JSON.parse(user)[field];
}

existFx();
