import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import { ReactComponent as CADIcon } from './icons/cadIcon.svg';
import { ReactComponent as TextIcon } from './icons/textIcon.svg';
import { ReactComponent as ImageIcon } from './icons/imageIcon.svg';
import { $activeFolderID, $treeFolderStore, setActiveFolderID, setIsOpen } from '../../../entities/folders';
import { $browserTab, BrowserTabs, switchTab } from '../../../entities/browserTab';
import { setSelectedFileID } from 'entities/files';
import './Browser.scss'


export default function Browser() {
    const folders = useStore($treeFolderStore);
    const browserTab = useStore($browserTab);
    const activeFolderID = useStore($activeFolderID)
    const [showStorageFolders, setShowStorageFolders] = useState<boolean>(false)
    const [showLibaryFolders, setShowLibaryFolders] = useState<boolean>(false)

    useEffect(() => {
        const parts = window.location.pathname.split('/');
        changeBrowserTab(parts[1]);
        if (parts.length === 3) {
            switch (parts[1]) {
                case 'storage': {
                    setActiveFolderID(parts[2]);
                    setShowStorageFolders(true)
                    break;
                }
                case 'library': {
                    changeBrowserTab(parts[2]);
                    setShowLibaryFolders(true);
                    break;
                }
            }
        }
    }, []);

    const changeBrowserTab = (tab: string) => {
        switch (tab) {
            case 'storage': switchTab(BrowserTabs.STORAGE); break;
            case 'library': switchTab(BrowserTabs.LIBRARY); break;
            case 'cad': switchTab(BrowserTabs.CAD); break;
            case 'text': switchTab(BrowserTabs.TEXT); break;
            case 'images': switchTab(BrowserTabs.IMAGES); break;
            case 'favourites': switchTab(BrowserTabs.FAVOURITES); break;
            case 'trash': switchTab(BrowserTabs.TRASH); break;
            default: switchTab(BrowserTabs.NONE); break;
        }
    };

    useEffect(() => {
        const updateBrowserParameters = () => {
            const parts = window.location.pathname.split('/');
            changeBrowserTab(parts[1]);
            if (parts.length === 3) {
                switch (parts[1]) {
                    case 'storage': setActiveFolderID(parts[2]); break;
                    case 'library': changeBrowserTab(parts[2]); break;
                }
            }
        };
        window.addEventListener('popstate', updateBrowserParameters);

        return () => {
            window.removeEventListener('popstate', updateBrowserParameters);
        };
    }, []);


    const addHistory = (tab: BrowserTabs, arg?: string) => {
        let newPath = `/${tab.toLocaleLowerCase()}`;
        if (tab === BrowserTabs.CAD || tab === BrowserTabs.TEXT || tab === BrowserTabs.IMAGES) newPath = `/library/${tab.toLocaleLowerCase()}`;
        if (arg) newPath = `/${tab.toLocaleLowerCase()}/${arg}`
        window.history.pushState(null, '', newPath);

        switchTab(tab);
        if (tab === BrowserTabs.STORAGE && arg) setActiveFolderID(arg);
    };


    const getFilesCount = (folderId: string) => {
        const length = 1;//filterFilesByFolderId(folderId).length; //TODO
        return length;
    };


    const renderSubfolders = (subfoldersIds: string[]) => {
        const subfolders = folders.filter((folder) => subfoldersIds.includes(folder.id));

        return (
            <div className={`folders_wrapper ${showStorageFolders ? 'folders_wrapper_open' : ''}`}>
                {
                    subfolders.map((subfolder) => (
                        <div className='folder' key={`subfolder_${subfolder.id}`}>
                            <div className={activeFolderID === subfolder.id ? 'folder_info_active' : 'folder_info'}
                                onClick={() => {
                                    setActiveFolderID(subfolder.id)
                                    addHistory(BrowserTabs.STORAGE, subfolder.id)
                                }}>
                                <div className='info'>
                                    {activeFolderID === subfolder.id && <div className='files_count'>{getFilesCount(subfolder.id)}</div>}

                                    {subfolder.subFoldersIDs?.length !== 0 ?
                                        <div className='browser__element_arrow' style={{ transform: subfolder.isOpen ? 'rotate(180deg)' : '' }}
                                            onClick={() => {
                                                if (subfolder.subFoldersIDs && subfolder.subFoldersIDs.length !== 0) {
                                                    setIsOpen(subfolder.id)
                                                }
                                            }} />
                                        :
                                        <div className='icon' />
                                    }
                                    <div className={`${subfolder.isOpen ? 'folder_open_icon' : 'folder_icon'} `} />
                                    {subfolder.name}
                                </div>
                            </div>
                            {(subfolder.subFoldersIDs && subfolder.subFoldersIDs.length !== 0) && subfolder.isOpen ? (
                                renderSubfolders(subfolder.subFoldersIDs)
                            ) : ''}
                        </div>
                    ))
                }
            </div>
        )
    };

    return (
        <div className="browser">
            <div className={classNames({
                'browser__element': true,
                'browser__element_active': (browserTab === BrowserTabs.STORAGE) && (activeFolderID === folders[0].id)
            })}
                onClick={() => {
                    setShowStorageFolders((prev) => !prev)
                    addHistory(BrowserTabs.STORAGE, folders[0].id)
                }}>
                {showStorageFolders && <div className='browser__element_separator' />}
                <div className='browser__element_left-parth'>
                    <div className='browser__element_arrow' style={{ transform: showStorageFolders ? 'rotate(180deg)' : '' }} />
                    <div className='browser__element_storage_icon' />
                    <div className='browser__element_name'>
                        {folders[0].name}
                    </div>

                </div>
                <div className='browser__element_plus' />
            </div>
            {showStorageFolders &&
                <div className='storage_folders'>
                    {folders[0].subFoldersIDs && renderSubfolders(folders[0].subFoldersIDs)}
                </div>
            }

            <div className={classNames({ 'browser__element': true, 'browser__element_active': browserTab === BrowserTabs.LIBRARY })}
                onClick={() => {
                    addHistory(BrowserTabs.LIBRARY)
                    setShowLibaryFolders((prev) => !prev)
                    setActiveFolderID(null)
                    setSelectedFileID(null)
                }}>
                <div className='browser__element_left-parth'>
                    <div className='browser__element_arrow' style={{ transform: showLibaryFolders ? 'rotate(180deg)' : '' }} />
                    <div className='browser__element_library_icon' />
                    <div className='browser__element_name'>
                        Library
                    </div>
                    <div className='browser__element_separator' />
                </div>
            </div>
            {showLibaryFolders &&

                <div className='library_folders_wrapper'>
                    <div
                        className={classNames({ 'library_folder': true, 'library_folder_active': browserTab === BrowserTabs.CAD })}
                        onClick={() => {
                            setActiveFolderID(null)
                            addHistory(BrowserTabs.CAD)
                        }}>
                        <CADIcon className='library_folder_icon' />
                        CAD
                    </div>
                    <div className={classNames({ 'library_folder': true, 'library_folder_active': browserTab === BrowserTabs.TEXT })}
                        onClick={() => {
                            setActiveFolderID(null)
                            addHistory(BrowserTabs.TEXT)
                        }}>

                        <TextIcon className='library_folder_icon' />
                        Text
                    </div>
                    <div className={classNames({ 'library_folder': true, 'library_folder_active': browserTab === BrowserTabs.IMAGES })}
                        onClick={() => {
                            setActiveFolderID(null)
                            addHistory(BrowserTabs.IMAGES)
                        }}>
                        <ImageIcon className='library_folder_icon' />
                        Images
                    </div>
                </div>}


            <div className={classNames({
                'browser__element': true,
                'browser__element-pad': true,
                'browser__element_active': browserTab === BrowserTabs.FAVOURITES
            })}
                onClick={() => {
                    addHistory(BrowserTabs.FAVOURITES)
                    setSelectedFileID(null)
                    setActiveFolderID(null)
                }}>
                <div className='browser__element_left-parth'>
                    <div className='browser__element_fav_icon' />
                    <div className='browser__element_name'>
                        Favourites
                    </div>
                </div>
            </div>

            <div className={classNames({
                'browser__element': true,
                'browser__element-pad': true,
                'browser__element_active': browserTab === BrowserTabs.TRASH
            })}
                onClick={() => {
                    addHistory(BrowserTabs.TRASH)
                    setActiveFolderID(null)
                    setSelectedFileID(null)
                }}>
                <div className='browser__element_left-parth'>
                    <div className='browser__element_trash_icon' />
                    <div className='browser__element_name'>
                        Trash
                    </div>
                </div>
            </div>
        </div>
    );
}