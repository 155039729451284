import { useEffect } from "react";

type Options = {
    modifier?: 'ctrlKey',
};

export default function useHotKey(key: string, fn: () => any, options: Options = {}) {
    useEffect(() => {
        function checkKey(event: KeyboardEvent) {
            if (options.modifier && !event[options.modifier]) return;
            if (event.key !== key) return;
            fn();
        }
        document.addEventListener('keydown', checkKey);

        return () => document.removeEventListener('keydown', checkKey);
    }, [key, fn, options]);
}