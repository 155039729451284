import { createEvent, createStore, sample } from "effector";
import { TagsType } from "./files";


export interface FilterI {
  id: string;
  name: string;
  type: TagsType;
  isOpen?: boolean;
  isActive: boolean;
  children: FilterI[];
  [key: string]: boolean | string | FilterI[] | undefined;
}

export interface ActiveFiltersI {
  id: string;
  name: string;
  type: TagsType;
  isOpen?: boolean;
  isActive: boolean;
}

export const defaultActiveFilters: ActiveFiltersI[] = [
  {
    id: "01",
    type: TagsType.APPLICATION,
    name: "Pupu",
    isOpen: false,
    isActive: false,
  },
];

export const defaultFilterTree: FilterI = {
  id: "0",
  name: "Root",
  type: TagsType.NONE,
  isOpen: false,
  isActive: false,
  children: [
    {
      id: "01",
      name: "Category",
      isOpen: false,
      type: TagsType.CATEGORY,
      isActive: false,
      children: [
        {
          id: "011",
          name: "CAD",
          type: TagsType.CATEGORY,
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "012",
          type: TagsType.CATEGORY,
          name: "Text",
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "013",
          type: TagsType.CATEGORY,
          name: "Images",
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "014",
          type: TagsType.CATEGORY,
          name: "Product",
          isOpen: false,
          isActive: false,
          children: [],
        },
      ],
    },

    {
      id: "02",
      name: "Object",
      isOpen: false,
      type: TagsType.OBJECT,
      isActive: false,
      children: [
        {
          id: "021",
          type: TagsType.OBJECT,
          name: "Shell",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0211",
              type: TagsType.OBJECT,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
        {
          id: "022",
          type: TagsType.OBJECT,
          name: "Furniture",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0221",
              type: TagsType.OBJECT,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
        {
          id: "023",
          type: TagsType.OBJECT,
          name: "Materials",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0231",
              type: TagsType.OBJECT,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
        {
          id: "024",
          type: TagsType.OBJECT,
          name: "Finishes",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0241",
              type: TagsType.OBJECT,
              name: "Windows",
              isOpen: false,
              isActive: false,
              children: [
                {
                  id: "02411",
                  type: TagsType.OBJECT,
                  name: "Skylights",
                  isOpen: false,
                  isActive: false,
                  children: [
                    {
                      id: "214111",
                      type: TagsType.OBJECT,
                      name: "Item",
                      isOpen: false,
                      isActive: false,
                      children: [],
                    },
                    {
                      id: "024112",
                      type: TagsType.OBJECT,
                      name: "Item",
                      isOpen: false,
                      isActive: false,
                      children: [],
                    },
                    {
                      id: "024113",
                      type: TagsType.OBJECT,
                      name: "Item",
                      isOpen: false,
                      isActive: false,
                      children: [],
                    },
                  ],
                },
                {
                  id: "02412",
                  type: TagsType.OBJECT,
                  name: "Windows",
                  isOpen: false,
                  isActive: false,
                  children: [
                    {
                      id: "024121",
                      type: TagsType.OBJECT,
                      name: "Filter",
                      isOpen: false,
                      isActive: false,
                      children: [],
                    },
                  ],
                },
                {
                  id: "02413",
                  type: TagsType.OBJECT,
                  name: "Automation",
                  isOpen: false,
                  isActive: false,
                  children: [
                    {
                      id: "024131",
                      type: TagsType.OBJECT,
                      name: "Filter",
                      isOpen: false,
                      isActive: false,
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              id: "0242",
              type: TagsType.OBJECT,
              name: "Floors",
              isOpen: false,
              isActive: false,
              children: [
                {
                  id: "02421",
                  type: TagsType.OBJECT,
                  name: "Filter",
                  isOpen: false,
                  isActive: false,
                  children: [],
                },
              ],
            },
          ],
        },
        {
          id: "025",
          type: TagsType.OBJECT,
          name: "Equipment",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0251",
              type: TagsType.OBJECT,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
        {
          id: "026",
          type: TagsType.OBJECT,
          name: "Construction materials",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0261",
              type: TagsType.OBJECT,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
      ],
    },
    {
      id: "03",
      name: "Subject",
      isOpen: false,
      type: TagsType.SUBJECT,
      isActive: false,
      children: [
        {
          id: "031",
          type: TagsType.SUBJECT,
          name: "Structural design",
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "032",
          type: TagsType.SUBJECT,
          name: "Architecture",
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "033",
          type: TagsType.SUBJECT,
          name: "Interior design",
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "034",
          type: TagsType.SUBJECT,
          name: "Civil engineering",
          isOpen: false,
          isActive: false,
          children: [],
        },
      ],
    },

    {
      id: "04",
      name: "Program",
      isOpen: false,
      type: TagsType.PROGRAM,
      isActive: false,
      children: [
        {
          id: "041",
          type: TagsType.PROGRAM,
          name: "Item",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0411",
              type: TagsType.PROGRAM,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
        {
          id: "042",
          type: TagsType.PROGRAM,
          name: "Item",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0421",
              type: TagsType.PROGRAM,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
        {
          id: "043",
          type: TagsType.PROGRAM,
          name: "Item",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0431",
              type: TagsType.PROGRAM,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
        {
          id: "044",
          type: TagsType.PROGRAM,
          name: "Item",
          isOpen: false,
          isActive: false,
          children: [
            {
              id: "0441",
              type: TagsType.PROGRAM,
              name: "Filter",
              isOpen: false,
              isActive: false,
              children: [],
            },
          ],
        },
      ],
    },
    {
      id: "05",
      name: "Application",
      isOpen: false,
      type: TagsType.APPLICATION,
      isActive: false,
      children: [
        {
          id: "051",
          type: TagsType.APPLICATION,
          name: "Item",
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "052",
          type: TagsType.APPLICATION,
          name: "Item",
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "053",
          type: TagsType.APPLICATION,
          name: "Item",
          isOpen: false,
          isActive: false,
          children: [],
        },
        {
          id: "054",
          type: TagsType.APPLICATION,
          name: "Item",
          isOpen: false,
          isActive: false,
          children: [],
        },
      ],
    },
  ],
};

type UpdateActiveFiltersPayload = {
  treeFilters: FilterI;
  arg: string;
};

export const $treeFilters = createStore<FilterI>(initFilter());

export const setIsFilterOpen = createEvent<string>();
export const setIsFilterActive = createEvent<string>();

export const $activeFilters = createStore<ActiveFiltersI[]>(initActiveFilters());

$treeFilters
  .on(setIsFilterOpen, (state, id) => {
    const updatedFilterState = findFilterAndUpdateState(state, id, "isOpen");
    return updatedFilterState;
  })
  .on(setIsFilterActive, (state, id) => {
    const updatedFilterState = findFilterAndUpdateState(state, id, "isActive");
    return updatedFilterState;
  });

export const updateActiveFilters = createEvent<UpdateActiveFiltersPayload>();

$activeFilters.on(updateActiveFilters, (state, { treeFilters, arg }) => {
  const filter = findFilter(arg, treeFilters);
  if (filter) {
    const existingIndex = state.findIndex((item) => item.id === filter.id);
    if (existingIndex !== -1) {
      state = state.filter((item) => item.id !== filter.id);
    } else {
      state = [...state, filter];
    }
  }
  return state;
});

sample({
  source: $treeFilters,
  clock: setIsFilterActive,
  fn: (treeFilters, arg) => ({ treeFilters, arg }),
  target: updateActiveFilters,
});

const findFilter = (id: string, filter: FilterI): FilterI | undefined => {
  if (filter.id === id) return filter;
  for (const subfilter of filter.children) {
    const foundFilter = findFilter(id, subfilter);
    if (foundFilter) {
      return foundFilter;
    }
  }
  return undefined;
};

const findFilterAndUpdateState = (
  filter: FilterI,
  id: string,
  fieldName: string
): FilterI => {
  if (filter.id === id) {
    if (typeof filter[fieldName] === "boolean") {
      const updatedFilter: FilterI = {
        ...filter,
        [fieldName]: !filter[fieldName],
      };
      return updatedFilter;
    }
  }
  const updatesFilters = filter.children.map((subfilter) =>
    findFilterAndUpdateState(subfilter, id, fieldName)
  );

  const updatedFilter: FilterI = {
    ...filter,
    children: updatesFilters,
  };
  return updatedFilter;
};

function initFilter(): FilterI {
  const folderTree = localStorage.getItem("filterTree");
  if (!folderTree) return defaultFilterTree;
  return JSON.parse(folderTree);
}

function initActiveFilters(): ActiveFiltersI[] {
  return defaultActiveFilters;
}
