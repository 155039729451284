import React, { ChangeEvent, useEffect, useState } from "react";
import { useStore } from "effector-react";

import Input from "../Input";
import { $selectedFileID, TagsType, addTag, updateTag } from "entities/files";

interface TagInputProps {
    fileId: string,
    tag?: string | null,
    tagType: TagsType,
    index?: number
}

function TagInput({ fileId, tag, tagType, index }: TagInputProps) {
    const [newTag, setNewTag] = useState<string>(tag ? tag : '')
    const selectedFileID = useStore($selectedFileID)

    useEffect(() => {
        setNewTag(tag ? tag : '')
    }, [selectedFileID, tag])

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewTag(value);
    };
    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            if (newTag !== tag) {
                if (!tag && (tagType === TagsType.SUBJECT || tagType === TagsType.APPLICATION)) {
                    addTag({ id: fileId, fieldName: tagType, value: newTag })
                }
                else {
                    updateTag({ id: fileId, fieldName: tagType, value: newTag, index: index })
                }

            }
        }
    };

    return (
        <Input
            value={newTag}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown} />
    )
}

export default TagInput