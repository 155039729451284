import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { ReactComponent as CheckboxSvg } from '../icons/checkbox.svg'
import './Checkbox.scss'
import { $files, selectAllFiles } from "entities/files";
import { useStore } from "effector-react";

export default function Checkbox() {
    const { activeFiles: files, selectedFilesID } = useStore($files)

    const allSelected = useMemo(() => selectedFilesID.length === files.length, [files, selectedFilesID]);
    const partSelected = useMemo(() => selectedFilesID.length > 0 && selectedFilesID.length < files.length, [files, selectedFilesID]);


    const handleClick = useCallback(() => {
        if (allSelected || partSelected) return selectAllFiles(false)
        selectAllFiles(true)
    }, [partSelected, allSelected]);

    return (
        <div
            className="files__table_head-checkbox"
            onClick={() => handleClick()}
        >
            <CheckboxSvg
                className={classNames({
                    'files__table_head-checkbox-icon': true,
                    'files__table_head-checkbox-icon_part-selected': partSelected,
                    'files__table_head-checkbox-icon_all-selected': allSelected,
                })}
            />
        </div>
    );
}
