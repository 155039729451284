import { createEvent, createStore, forward } from 'effector';
import { mockAItags } from '../components/FilesTable/config';
import { $activeFolderID, FolderData } from './folders';
import fileData from '../data/fileData.json';
import folderDataArray from '../data/folderData.json';
import { addFavouritesToStorage } from 'services/addFavouritesToStorage';

export interface AccesI {
  name: string;
  avatar: string;
}

export enum TagsType {
  NONE = "NONE",
  CATEGORY = "CATEGORY",
  OBJECT = "OBJECT",
  SUBJECT = "SUBJECT",
  PROGRAM = "PROGRAM",
  APPLICATION = "APPLICATION",
}

export interface TagsI {
  category: string | null;
  object: string | null;
  subject: string[] | null;
  program: string | null;
  application: string[] | null;
  [key: string]: string | string[] | null;
}

export interface FileData {
  filename: string;
  folderId: string;
  originalname: string;
  extension: string;
  preview: string;
  date: string;
  size: string;
  access: AccesI[];
  note: string;
  tags: TagsI;
  AItags?: TagsI;
}

export interface Files {
  allFiles: FileData[];
  allFolders: FolderData[] | null;
  activeFiles: FileData[];
  activeFolders: FolderData[] | null;
  selectedFilesID: string[];
  favoritedFilesID: string[];
  favoritedFoldersID: string[];
}

interface EditTagProps {
  id: string;
  fieldName: TagsType;
  value: string;
  index?: number;
}

interface RemoveTagProps {
  id: string;
  fieldName: TagsType;
  index?: number;
}

interface AddTagProps {
  id: string;
  fieldName: TagsType;
  value: string;
}
export const $files = createStore<Files>(getFilesByFolder());

export const changeOrder = createEvent<{
  source: number;
  destination: number;
}>();

export const updateActiveContent = createEvent<string | null>();
export const selectFile = createEvent<string>();
export const addFiletoFavourites = createEvent<string>();
export const addFoldertoFavourites = createEvent<string>();
export const selectAllFiles = createEvent<boolean>();
export const updateTag = createEvent<EditTagProps>();
export const removeTag = createEvent<RemoveTagProps>();
export const addTag = createEvent<AddTagProps>();

export const generateAITags = createEvent<boolean | undefined>();
export const generateFileAITags = createEvent<string>();
export const generateOneAITag = createEvent<RemoveTagProps>();

export const approveAllFileAITags = createEvent<string>();
export const approveAllAITags = createEvent();
export const approveOneAITag = createEvent<EditTagProps>();
export const updateOneAITagTag = createEvent<EditTagProps>();

export const removeOneAITag = createEvent<RemoveTagProps>();
export const removeFileAITags = createEvent<string>();

export const $selectedFileID = createStore<string | null>(null);
export const setSelectedFileID = createEvent<string | null>();

export const $AIMode = createStore<boolean>(false);
export const toggleAIMode = createEvent();

export const $tagsMode = createStore<boolean>(false);
export const toggleTagsMode = createEvent<boolean | undefined>();

$tagsMode.on(toggleTagsMode, (state, value) => (value ? value : !state));

$AIMode.on(toggleAIMode, (state) => !state);

$AIMode.watch((state) => {
  generateAITags(state);
  if (!state) selectAllFiles(false);
});

$activeFolderID.watch((state) => {
  updateActiveContent(state);
});

approveAllAITags.watch(() => {
  toggleAIMode();
  toggleTagsMode(true);
});

forward({
  from: approveOneAITag.map(({ id, fieldName, value }) => ({
    id,
    fieldName,
    value,
  })),
  to: updateTag,
});

$selectedFileID.on(setSelectedFileID, (_, value) => value);

$files
  .on(updateActiveContent, (state, folderID) => {
    return {
      ...state,
      activeFiles: [],//filterFilesByFolderId(folderID), //TODO
      activeFolders: filterFoldersByFolderId(folderID),
    };
  })
  .on(changeOrder, (state, { source, destination }) => {
    const files = Array.from(state.activeFiles);
    const [element] = files.splice(source, 1);
    files.splice(destination, 0, element);

    return {
      ...state,
      activeFiles: files,
    };
  })
  .on(selectFile, (state, id) => {
    const selected = state.selectedFilesID.includes(id)
      ? state.selectedFilesID.filter((fileId) => fileId !== id)
      : [...state.selectedFilesID, id];

    return {
      ...state,
      selectedFilesID: selected,
    };
  })
  .on(selectAllFiles, (state, isSelection) => {
    if (isSelection) {
      const allFileIds = state.activeFiles.map((file) => file.filename);
      return {
        ...state,
        selectedFilesID: allFileIds,
      };
    } else {
      return {
        ...state,
        selectedFilesID: [],
      };
    }
  })
  .on(addFiletoFavourites, (state, id) => {
    addFavouritesToStorage(id, "fav_files");
    const favorites = state.favoritedFilesID.includes(id)
      ? state.favoritedFilesID.filter((fileId) => fileId !== id)
      : [...state.favoritedFilesID, id];

    return {
      ...state,
      favoritedFilesID: favorites,
    };
  })
  .on(addFoldertoFavourites, (state, id) => {
    addFavouritesToStorage(id, "fav_folders");
    const favorites = state.favoritedFoldersID.includes(id)
      ? state.favoritedFoldersID.filter((fileId) => fileId !== id)
      : [...state.favoritedFoldersID, id];

    return {
      ...state,
      favoritedFoldersID: favorites,
    };
  })
  .on(updateTag, (state, { id, fieldName, value, index }) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename !== id) return file;
      let updatedTag = file.tags[fieldName.toLowerCase()];
      if (
        Array.isArray(updatedTag) &&
        index !== undefined &&
        index >= 0 &&
        index < updatedTag.length
      ) {
        updatedTag[index] = value;
      } else if (
        fieldName === TagsType.APPLICATION ||
        fieldName === TagsType.SUBJECT
      ) {
        Array.isArray(updatedTag)
          ? updatedTag.push(value)
          : (updatedTag = [value]);
      } else {
        updatedTag = value;
      }
      return {
        ...file,
        tags: {
          ...file.tags,
          [fieldName.toLowerCase()]: updatedTag,
        },
      };
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })
  .on(removeTag, (state, { id, fieldName, index }) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename !== id) return file;
      let updatedTag = file.tags[fieldName.toLowerCase()];
      if (
        Array.isArray(updatedTag) &&
        index !== undefined &&
        index >= 0 &&
        index < updatedTag.length
      ) {
        updatedTag.splice(index, 1);
      } else {
        updatedTag = null;
      }
      return {
        ...file,
        tags: {
          ...file.tags,
          [fieldName.toLowerCase()]: updatedTag,
        },
      };
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })
  .on(addTag, (state, { id, fieldName, value }) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename !== id) return file;
      let updatedTags = file.tags[fieldName.toLowerCase()];
      if (Array.isArray(updatedTags)) {
        updatedTags.push(value);
      } else {
        updatedTags = [value];
      }
      return {
        ...file,
        tags: {
          ...file.tags,
          [fieldName.toLowerCase()]: updatedTags,
        },
      };
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })
  .on(generateAITags, (state, AIMode) => {
    if (state.selectedFilesID.length === 0) {
      const updatedFiles = state.activeFiles.map((file) => {
        let generatedAItags = undefined;
        if (AIMode) generatedAItags = createRandomObject();
        return {
          ...file,
          AItags: generatedAItags,
        };
      });
      return {
        ...state,
        activeFiles: updatedFiles,
      };
    } else {
      const updatedFiles = state.activeFiles.map((file) => {
        if (!state.selectedFilesID.includes(file.filename)) return file;
        let generatedAItags = undefined;
        if (AIMode) generatedAItags = createRandomObject();
        return {
          ...file,
          AItags: generatedAItags,
        };
      });
      return {
        ...state,
        activeFiles: updatedFiles,
      };
    }
  })

  .on(generateFileAITags, (state, id) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename !== id) return file;
      return {
        ...file,
        AItags: createRandomObject(),
      };
    });
    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })
  .on(generateOneAITag, (state, { id, fieldName, index }) => {
    const updatedFiles = state.activeFiles.map((file) => {
      const newTag = getRandomValue(fieldName);
      if (file.filename !== id || !file.AItags || !newTag) return file;
      let updatedTag = file.AItags[fieldName.toLowerCase()];

      if (
        Array.isArray(updatedTag) &&
        index !== undefined &&
        index >= 0 &&
        index < updatedTag.length
      ) {
        updatedTag[index] = newTag;
      } else updatedTag = newTag;

      return {
        ...file,
        AItags: {
          ...file.AItags,
          [fieldName.toLowerCase()]: updatedTag,
        },
      };
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })
  .on(approveAllAITags, (state) => {
    const updatedFiles = state.activeFiles.map((file) => {
      const { AItags, ...updatedFile } = file;
      let updatedTags: TagsI = { ...file.tags };

      for (const key in AItags) {
        if (AItags.hasOwnProperty(key) && updatedTags.hasOwnProperty(key)) {
          const updatedValue = AItags[key];
          const targetValue = updatedTags[key];
          if (updatedValue !== undefined) {
            if (Array.isArray(targetValue) && Array.isArray(updatedValue)) {
              updatedTags[key] = targetValue.concat(updatedValue);
            } else {
              updatedTags[key] = updatedValue;
            }
          }
        }
      }
      return {
        ...updatedFile,
        tags: updatedTags,
        AItags: undefined,
      };
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })
  .on(approveOneAITag, (state, { id, fieldName, value, index }) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename === id) {
        if (!file.AItags) return file;
        let updatedTag = file.AItags[fieldName.toLowerCase()];
        if (
          Array.isArray(updatedTag) &&
          index !== undefined &&
          index >= 0 &&
          index < updatedTag.length
        ) {
          updatedTag.splice(index, 1);
        } else {
          updatedTag = null;
        }
        return {
          ...file,
          AItags: {
            ...file.AItags,
            [fieldName.toLowerCase()]: updatedTag,
          },
        };
      }
      return file;
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })

  .on(updateOneAITagTag, (state, { id, fieldName, value, index }) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename !== id || !file.AItags) return file;
      let updatedTag = file.AItags[fieldName.toLowerCase()];
      if (
        Array.isArray(updatedTag) &&
        index !== undefined &&
        index >= 0 &&
        index < updatedTag.length
      ) {
        updatedTag[index] = value;
      } else if (
        fieldName === TagsType.APPLICATION ||
        fieldName === TagsType.SUBJECT
      ) {
        Array.isArray(updatedTag)
          ? updatedTag.push(value)
          : (updatedTag = [value]);
      } else {
        updatedTag = value;
      }
      return {
        ...file,
        AItags: {
          ...file.AItags,
          [fieldName.toLowerCase()]: updatedTag,
        },
      };
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })

  .on(removeFileAITags, (state, id) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename !== id || !file.AItags) return file;
      return {
        ...file,
        AItags: undefined,
      };
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })
  .on(removeOneAITag, (state, { id, fieldName, index }) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename !== id || !file.AItags) return file;
      let updatedTag = file.AItags[fieldName.toLowerCase()];
      if (
        Array.isArray(updatedTag) &&
        index !== undefined &&
        index >= 0 &&
        index < updatedTag.length
      ) {
        updatedTag.splice(index, 1);
      } else {
        updatedTag = null;
      }
      return {
        ...file,
        AItags: {
          ...file.AItags,
          [fieldName.toLowerCase()]: updatedTag,
        },
      };
    });
    return {
      ...state,
      activeFiles: updatedFiles,
    };
  })

  .on(approveAllFileAITags, (state, id) => {
    const updatedFiles = state.activeFiles.map((file) => {
      if (file.filename !== id) return file;
      const { AItags, ...updatedFile } = file;
      let updatedTags: TagsI = { ...file.tags };

      for (const key in AItags) {
        if (AItags.hasOwnProperty(key) && updatedTags.hasOwnProperty(key)) {
          const updatedValue = AItags[key];
          const targetValue = updatedTags[key];
          if (updatedValue !== undefined) {
            if (Array.isArray(targetValue) && Array.isArray(updatedValue)) {
              updatedTags[key] = targetValue.concat(updatedValue);
            } else {
              updatedTags[key] = updatedValue;
            }
          }
        }
      }
      return {
        ...updatedFile,
        tags: updatedTags,
        AItags: undefined,
      };
    });

    return {
      ...state,
      activeFiles: updatedFiles,
    };
  });

function createRandomObject() {
  const randomObject = {
    category: getRandomValue(TagsType.CATEGORY),
    object: getRandomValue(TagsType.OBJECT),
    subject: getRandomArrayValue(TagsType.SUBJECT),
    program: getRandomValue(TagsType.PROGRAM),
    application: getRandomArrayValue(TagsType.APPLICATION),
  };

  return randomObject;
}

function getRandomValue(type: TagsType) {
  const tags = mockAItags.find((tag) => tag.type === type);
  if (tags) {
    const items = tags.items;
    const randomIndex = Math.floor(Math.random() * items.length);
    return items[randomIndex];
  }
  return null;
}

function getRandomArrayValue(type: TagsType) {
  const tags = mockAItags.find((tag) => tag.type === type);
  if (tags) {
    const items = tags.items;
    const randomItemsCount = Math.floor(Math.random() * 4) + 1;
    const randomItems = [];

    for (let i = 0; i < randomItemsCount; i++) {
      const randomIndex = Math.floor(Math.random() * items.length);
      randomItems.push(items[randomIndex]);
    }
    return randomItems;
  }
  return null;
}

function getFilesByFolder() {
  return {
    allFiles: getAllFiles(),
    allFolders: getAllFolders(),
    activeFiles: filterFilesByFolderId("0"),
    activeFolders: filterFoldersByFolderId("0"),
    selectedFilesID: [],
    favoritedFilesID: getFavorites("fav_files"),
    favoritedFoldersID: getFavorites("fav_folders"),
  };
}

function getAllFiles(): FileData[] {
  return fileData;
}

function getAllFolders() : FolderData[] | null {
  return folderDataArray;
}

export function filterFilesByFolderId(folderId: string | null): FileData[] {
  if (folderId) return fileData.filter((file) => file.folderId === folderId);
  else return fileData;
}

function filterFoldersByFolderId(folderId: string | null): FolderData[] | null {
  if (folderId) {
    const parentFolder = folderDataArray.find(
      (folder) => folder.id === folderId
    );
    if (parentFolder && parentFolder.subFoldersIDs) {
      const subFolders = folderDataArray.filter((folder) =>
        parentFolder.subFoldersIDs.includes(folder.id)
      );
      return subFolders;
    }
  }
  return null;
}

function getFavorites(key: string) {
  return JSON.parse(localStorage.getItem(key) || "[]");
}
