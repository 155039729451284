import classNames from 'classnames';
import './Input.scss';


type InputProps = {
    placeholder?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    onKeyDown?:  (event: React.KeyboardEvent<HTMLInputElement>) => void;
    className?: string;
    disabled?: boolean;
};

export default function Input({ placeholder='Empty field', value, onChange, onBlur, onKeyDown, className = '', disabled = false }: InputProps) {
    return (
        <input
            className={classNames({
                input: true,
                [className]: Boolean(className),
            })}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
        />
    );
}