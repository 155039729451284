import { createEvent, createStore } from "effector";

export enum BrowserTabs {
  NONE = "NONE",
  STORAGE = "STORAGE",
  LIBRARY = "LIBRARY",
  CAD="CAD",
  TEXT="TEXT",
  IMAGES="IMAGES",
  FAVOURITES = "FAVOURITES",
  TRASH = "TRASH",
}

export const $browserTab = createStore<BrowserTabs>(BrowserTabs.NONE);
export const switchTab = createEvent<BrowserTabs>();

$browserTab
.on(switchTab, (_, value) => value);
