import { useStore } from 'effector-react';
import WorkScreen from '../WorkScreen';
import { $splitMode } from '../../entities/splitMode';
import './Upload.scss';


export default function Upload() {
    const splitMode = useStore($splitMode)

    return (
        <div className="upload">
            <WorkScreen />
            {splitMode && <WorkScreen />}
        </div>
    );
}
