import { DragEvent, useEffect, useRef } from "react";
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import FileRow from './FileRow';
import Checkbox from './Checkbox';
import FileTile from './FileTile';
import { $splitMode } from '../../entities/splitMode';
import { $AIMode, $files, $tagsMode, changeOrder } from 'entities/files';
import { FolderRow } from './FileRow/FileRow';
import { $activeFolderID } from 'entities/folders';
import { FolderTile } from './FileTile/FileTile';
import { uploadFile } from 'services';
import './FilesTable.scss';


const permissibleExtension: Array<string | undefined> = ['jpg', 'png', 'webp', 'pdf', 'docx', 'dwg', 'skp', 'rfa', '3ds'];

export default function FilesTable({ tableState, isFavourites }: { tableState: boolean, isFavourites?: boolean }) {
    const splitMode = useStore($splitMode)
    const { activeFiles, allFiles, allFolders, favoritedFilesID, favoritedFoldersID, activeFolders } = useStore($files)
    const activeFolderID = useStore($activeFolderID)
    const fileTableRef = useRef<HTMLDivElement>(null);

    function drop(e: DragEvent<HTMLDivElement>) {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (!file) return console.error(`File is undefined.`);
        if (!file.name.includes('.')) return console.error(`File doesn't have extension.`);
        const extension = file.name.split('.').pop();
        if (!permissibleExtension.includes(extension)) return console.error(`File doesn't have permissible extension.`);
        uploadFile(file);
    }

    function dragOver(e: DragEvent<HTMLDivElement>) {
        e.preventDefault();
    }


    useEffect(() => {
        const scrollToTop = () => {
            if (fileTableRef.current) {
                fileTableRef.current.scrollTop = 0;
            }
        };
        scrollToTop();
    }, [activeFolderID])

    const handleDragEnd = (event: DropResult) => {
        if (!event.destination) return;
        if (event.destination.index === event.source.index) return;
        changeOrder({ source: event.source.index, destination: event.destination.index })
    };


    return (
        <div className="table_wrapper" ref={fileTableRef}>
            {tableState ? (
                <>
                    <FileTableHeader />
                    <div className={classNames({ 'files__table': true, 'files__table-splitmode': splitMode })}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="file-list">
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {isFavourites ? (
                                            allFiles.map((file, index) => (
                                                favoritedFilesID.includes(file.filename) &&
                                                <FileRow file={file} index={index} key={`droppable-file_${file.filename}`} />
                                            ))
                                        ) : (
                                            activeFiles.map((file, index) => (
                                                <FileRow file={file} index={index} key={`droppable-file_${file.filename}`} />
                                            ))

                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {isFavourites ? (
                            allFolders?.map((folder, index) => (
                                favoritedFoldersID.includes(folder.id) &&
                                <FolderRow folder={folder} key={`row_folder_${index}`} />))
                        ) : (
                            activeFolders?.map((folder, index) => (
                                <FolderRow folder={folder} key={`row_folder_${index}`} />
                            ))
                        )}
                    </div>
                    <div className="table__upload" children="upload" onDrop={drop} onDragOver={dragOver} />
                </>
            ) : (
                <div className="files__tile">
                    {isFavourites ? (
                        <>
                            {allFiles.map((file, index) => (
                                isFavourites && favoritedFilesID.includes(file.filename) &&
                                <FileTile file={file} key={index} />
                            ))}
                            {allFolders?.map((folder, index) => (
                                favoritedFoldersID.includes(folder.id) &&
                                <FolderTile folder={folder} key={`tile_folder_${index}`} />
                            ))
                            }

                        </>
                    ) : (
                        <>
                            {activeFiles.map((file, index) => (
                                <FileTile file={file} key={index} />
                            ))}
                            {
                                activeFolders?.map((folder, index) => (
                                    <FolderTile folder={folder} key={`tile_folder_${index}`} />
                                ))
                            }
                        </>

                    )}
                </div>
            )
            }
        </div>
    );
}


function FileTableHeader() {

    const tagsMode = useStore($tagsMode);
    const AIMode = useStore($AIMode);
    return (
        <div className={classNames({ "files__table_head": !tagsMode || !AIMode, 'files__table_head-tagsMode': tagsMode || AIMode })}>
            <div className="files__table_head-cell" />
            <div className="files__table_head-cell" id="checkbox">
                <Checkbox />
            </div>
            <div className="files__table_head-cell">Name</div>

            {tagsMode || AIMode ? (<>
                <div className="files__table_head-cell">Category</div>
                <div className="files__table_head-cell">Object</div>
                <div className="files__table_head-cell">Subject</div>
                <div className="files__table_head-cell">Program</div>
                <div className="files__table_head-cell">Application</div>

            </>) : (<>

                <div className="files__table_head-cell">Date modified</div>
                <div className="files__table_head-cell">Size</div>
                <div className="files__table_head-cell">Access</div>
                <div className="files__table_head-cell" /></>)}

            <div className="files__table_head-cell">
                <div className="files__table-plus_icon" />
            </div>
        </div>
    );
}

interface FileTableStatusBarProps {
    tableState: boolean
    setTableState: (state: boolean) => void;
}

export function FileTableStatusBar({ tableState, setTableState }: FileTableStatusBarProps) {
    return (
        <div className="files__table_status-bar">
            <p>Status bar text</p>
            <div className="files__table_status-bar_icons">
                <div className={`list-icon ${tableState && 'active'}`} onClick={() => setTableState(true)} />
                <div className={`tiles-icon ${!tableState && 'active'}`} onClick={() => setTableState(false)} />
            </div>

        </div>
    );
}