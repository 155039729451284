import React, {useState } from "react";
import './StatusBar.scss'
import { ReactComponent as FrameIcon } from '../icons/frame.svg';
import { ReactComponent as CrossFrameIcon } from '../icons/cross_frame.svg';
import { $splitMode, toggleSplitMode } from "../../../entities/splitMode";
import { useStore } from "effector-react";

function StatusBar() {
    const history = ['Home', 'Storage', 'Folder name']
    const splitMode = useStore($splitMode)
  
    return (
        <div className="status__bar" 
        >
            <div className="status__bar_left_part">
                <div className="status__bar_arrows">
                    <div className="status__bar_back_arrow" onClick={() => window.history.back()} />
                    <div className="status__bar_next_arrow" onClick={() => window.history.forward()} />
                </div>
                <div className="status__bar_history">
                    {history.map((item, index) => (
                        <div key={`history-${index}`} className="status__bar_history-item">
                            <p >{item}</p>
                            {index !== history.length - 1 && <div className="status__bar_history_chevron" />}
                        </div>
                    ))}
                    <div className="status__bar_history_copy_icon" />
                </div>
            </div>
            <div className="status__bar_right_part">
                <SearchBar />
                <div className="status__bar_right_part_icons"
                    onClick={() => toggleSplitMode()}>
                    {splitMode ? <FrameIcon /> : <CrossFrameIcon />}
                </div>
            </div>
        </div>
    )
}

export default StatusBar


function SearchBar() {
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>();

    function handleKeyPress(event: any) {
        if (event.key === 'Enter') {
            // console.log(searchValue)
        }
    }

    return (
        <>
            <div className={`status__bar_search_wrapper ${isSearchOpen ? 'status__bar_search_wrapper_open' : ''}`}>
                <div className="status__bar_search_icon" />
                <input
                    className="status__bar_search_input"
                    type="text"
                    placeholder="Search"
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <div className="status__bar_search_close" onClick={() => setIsSearchOpen(false)} />
            </div>
            {!isSearchOpen && <div className="status__bar_search_icon" onClick={() => setIsSearchOpen(true)} />}
        </>

    )
}
