import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import useClickOutside from '../../../services/useClickOutside';
import classNames from 'classnames';
import { $AIMode, $files, $tagsMode, $userId, FileData, TagsType, addFiletoFavourites, addFoldertoFavourites, setSelectedFileID } from '../../../entities';
import TagSelect from '../TagSelect';
import Tag from '../Tag';
import AITag from '../AITag';
import useIsFileSelected from 'services/useIsFileSelected';
import FileCheckbox from '../FileCheckbox';
import { FolderData, setActiveFolderID } from 'entities/folders';
import useIsFavourite from 'services/useIsFavourite';
import config from 'config';
import './FileTile.scss';


export default function FileTile({ file }: { file: FileData }) {
    const userId = useStore($userId);
    const isFavourite = useIsFavourite(file.filename, 'favoritedFilesID');
    const isSelected = useIsFileSelected(file.filename);
    const tagsMode = useStore($tagsMode);

    return (
        <div className="file_tile-wrapper">
            <div className={`file_tile ${isSelected && 'file_tile_active'}`}
                onClick={() => setSelectedFileID(file.filename)}>
                <div className="file_tile-image">
                    <div className="file_tile-chexbox">
                        <FileCheckbox fileId={file.filename} />
                    </div>
                    <img src={`${config.minioUrl}/backup/${userId}/${file.filename}`} alt={file.extension} />
                    <Settings />
                </div>
                <div className="file_tile-text">
                    <p className="file_tile-name">{file.originalname}</p>
                    {tagsMode ? (
                        <TileTagsElement file={file} />
                    ) : (< p className="file_tile-size">{file.size}</p>)}

                    <div className={classNames({ 'file_tile__fav': true, 'file_tile__fav_active': isFavourite })}
                        onClick={() => addFiletoFavourites(file.filename)} />
                </div>
            </div>
        </div >
    );
}


function Settings() {
    const [isSettingsOpen, setIsSettigsOpen] = useState<boolean>(false);
    const settingsRef = useClickOutside(() => setIsSettigsOpen(false));

    return (
        <div className="file_tile-settings-wrapper"
            ref={settingsRef}
            onClick={() => setIsSettigsOpen((prev) => !prev)}>
            <div className="file_tile-settings" />
            <div className="file_tile-settings_menu"
                style={{ display: isSettingsOpen ? 'flex' : 'none' }}>
                <div className="file_tile-settings_menu_item">Delete</div>
                <div className="file_tile-settings_menu_item">Something</div>
            </div>
        </div>
    );
}



function TileTagsElement({ file }: { file: FileData }) {
    const AIMode = useStore($AIMode);

    return (
        <div className="file_tile__tag-cell">

            {file.AItags && file.AItags.category ? (
                <AITag tag={file.AItags.category} key={`ai-${file.AItags.category}`} fileId={file.filename} tagType={TagsType.CATEGORY} />
            ) : (
                <TagSelect tag={file.tags.category} key={`tag-${file.tags.category}}`} fileId={file.filename} />)
            }

            {file.AItags && file.AItags.object ? (
                file.AItags && file.AItags.object &&
                <AITag tag={file.AItags.object} key={`ai-${file.AItags.object}`} fileId={file.filename} tagType={TagsType.OBJECT} />

            ) : (
                file.tags && file.tags.object &&
                <Tag tag={file.tags.object} key={`tag-${file.tags.object}`} fileId={file.filename} tagType={TagsType.OBJECT} />)
            }

            {file.tags && file.tags.subject && file.tags.subject.length !== 0 &&
                file.tags.subject.map((item, index) => (
                    <Tag tag={item} key={`tag-${item}-${index}`} fileId={file.filename} tagType={TagsType.SUBJECT} index={index} />
                ))
            }
            {AIMode &&
                file.AItags && file.AItags.subject && file.AItags.subject.length !== 0 &&
                file.AItags.subject.map((item, index) => (
                    <AITag tag={item} key={`ai-${item}-${index}`} fileId={file.filename} tagType={TagsType.SUBJECT} index={index} />
                ))
            }

            {file.AItags && file.AItags.program ? (
                file.AItags && file.AItags.program &&
                <AITag tag={file.AItags.program} key={`ai-${file.AItags.program}`} fileId={file.filename} tagType={TagsType.PROGRAM} />
            ) : (
                file.tags && file.tags.program &&
                <Tag tag={file.tags.program} key={`tag-${file.tags.program}`} fileId={file.filename} tagType={TagsType.PROGRAM} />)
            }

            {file.tags && file.tags.application && file.tags.application.length !== 0 &&
                file.tags.application.map((item, index) => (
                    <Tag tag={item} key={`tag-${item}-${index}`} fileId={file.filename} tagType={TagsType.APPLICATION} index={index} />
                ))
            }
            {AIMode &&
                file.AItags && file.AItags.application && file.AItags.application.length !== 0 &&
                file.AItags.application.map((item, index) => (
                    <AITag tag={item} key={`ai-${item}-${index}`} fileId={file.filename} tagType={TagsType.APPLICATION} index={index} />
                ))
            }
        </div>
    )
}


export function FolderTile({ folder }: { folder: FolderData }) {
    const isFavourite = useIsFavourite(folder.id, 'favoritedFoldersID')
    const [previewImages, setPreviewImages] = useState<string[]>();
    const { allFiles: files } = useStore($files)

    useEffect(() => {
        const getFolderPreviewImages = (folderID: string) => {
            const filesInFolder = files.filter((file) => file.folderId === folderID);
            if (filesInFolder && filesInFolder.length !== 0) {
                const sliceCount = filesInFolder.length >= 3 ? 3 : filesInFolder.length;
                return filesInFolder.slice(0, sliceCount).map((file) => file.preview);
            }
            return [];
        };

        setPreviewImages(getFolderPreviewImages(folder.id));
    }, [files, folder.id]);

    const addHistory = (folderID: string) => {
        const newPath = `/storage/${folderID}`
        window.history.pushState(null, '', newPath);
        setActiveFolderID(folderID);
    };

    return (
        <div className="file_tile-wrapper">
            <div className={`file_tile`}
                onDoubleClick={() => addHistory(folder.id)}>
                <div className="file_tile-image">
                    <div className="file_tile-chexbox">
                        <FileCheckbox fileId={folder.id} />
                    </div>
                    {previewImages?.length === 0 || previewImages?.length === 1 ? <div className="folder-big-icon" /> :
                        <div className="folder-preview-images">
                            {previewImages?.map((image, index) => (
                                <img src={image} className={`preview-image-${index}`} key={`folder-review_${index}`} alt=" " />
                            ))}
                        </div>}
                    <Settings />
                </div>
                <div className="file_tile-text">
                    <div className="folder-name-wrapper">
                        <div className="folder-tile-icon" />
                        <p className="file_tile-name">{folder.name}</p>
                    </div>
                    < p className="file_tile-size">{folder.size}</p>

                    <div className={classNames({ 'file_tile__fav': true, 'file_tile__fav_active': isFavourite })}
                        onClick={() => addFoldertoFavourites(folder.id)} />
                </div>
            </div>
        </div >
    )

}