import { useStore } from "effector-react";
import { $files } from "entities/files";

import { useState, useEffect } from "react";

export default function useIsFavourite(fileId: string, fieldName: 'favoritedFilesID' | 'favoritedFoldersID'): boolean {
  const fav = useStore($files)[fieldName];
  const [isFavourite, setIsFavourite] = useState<boolean>(false);

  useEffect(() => {
    setIsFavourite(fav.includes(fileId));
  }, [fileId, fav]);

  return isFavourite;
}
