import LeftBar from './components/LeftBar';
import Upload from './components/Upload';
import './App.scss';
import Preview from './components/Preview';

function App() {
  return (
    <div className="app">
      <div className='window'>
        <LeftBar />
        <Upload />
        <Preview/>
      </div>
    </div>
  );
}

export default App;
