import React, { useEffect, useState } from "react"
import './EditMenu.scss'
import useHotKey from "services/useHotKey";

interface TagEditMenuProps {
    onEditClick: () => void;
    onCopyClick: () => void;
    onPasteClick: () => void;
    onDeleteClick: () => void;
}

export function TagEditMenu({ onEditClick, onCopyClick, onPasteClick, onDeleteClick }: TagEditMenuProps) {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const [isCopied, setIsCopied] = useState<boolean>(false)

    useEffect(() => {
        const checkClipboard = async () => {
            try {
                const text = await navigator.clipboard.readText();
                setIsCopied(text.trim() !== '')
            } catch (error) {
                console.error('Clipboard Error', error);
            }
        };
        checkClipboard();

    }, []);
    
    useHotKey('p', onEditClick, { modifier: 'ctrlKey' });
    useHotKey('c', onCopyClick, { modifier: 'ctrlKey' });

    const menuItems = [
        { iconClass: 'tag-edit-menu-item-edit_icon', label: 'Edit', onClick: onEditClick },
        { iconClass: 'tag-edit-menu-item-copy_icon', label: 'Copy', onClick: onCopyClick },
        { iconClass: 'tag-edit-menu-item-paste_icon', label: 'Paste', onClick: onPasteClick },
        { iconClass: 'tag-edit-menu-item-delete_icon', label: 'Delete', onClick: onDeleteClick },
    ];

    return (
        <div className="tag-edit-menu">
            {menuItems.map((item, index) => (
                <div
                    className="tag-edit-menu-item"
                    style={{ display: item.label === 'Paste' && !isCopied ? 'none' : 'flex' }}
                    key={`edit_menu-${index}`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={item.onClick}>
                    <div className={item.iconClass} />
                    <p>{item.label}</p>
                    <div
                        className="tag-edit-menu-item-separator"
                        style={{ display: hoveredIndex === index + 1 ? 'none' : 'flex' }}
                    />
                </div>
            ))}
        </div>
    );
}


interface AITagEditMenuProps {
    onApproveClick: () => void;
    onRetryClick: () => void;
    onCopyClick: () => void;
    onPasteClick: () => void;
    onRemoveClick: () => void;
}

export function AITagEditMenu({ onApproveClick, onRetryClick, onCopyClick, onPasteClick, onRemoveClick }: AITagEditMenuProps) {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const [isCopied, setIsCopied] = useState<boolean>(false)

    useEffect(() => {
        const checkClipboard = async () => {
            try {
                const text = await navigator.clipboard.readText();
                setIsCopied(text.trim() !== '')
            } catch (error) {
                console.error('Clipboard Error', error);
            }
        };
        checkClipboard();

    }, []);

    const menuItems = [
        { iconClass: 'tag-edit-menu-item-approve_icon', label: 'Approve', onClick: onApproveClick },
        { iconClass: 'tag-edit-menu-item-retry_icon', label: 'Retry', onClick: onRetryClick },
        { iconClass: 'tag-edit-menu-item-copy_icon', label: 'Copy', onClick: onCopyClick },
        { iconClass: 'tag-edit-menu-item-paste_icon', label: 'Paste', onClick: onPasteClick },
        { iconClass: 'tag-edit-menu-item-remove_icon', label: 'Remove', onClick: onRemoveClick },
    ];


    useHotKey('c', onCopyClick, { modifier: 'ctrlKey' });

    return (
        <div className="tag-edit-menu">
            {menuItems.map((item, index) => (
                <div
                    className="tag-edit-menu-item"
                    style={{ display: item.label === 'Paste' && !isCopied ? 'none' : 'flex' }}
                    key={`edit_menu-${index}`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={item.onClick}>
                    <div className={item.iconClass} />
                    <p>{item.label}</p>
                    <div
                        className="tag-edit-menu-item-separator"
                        style={{ display: hoveredIndex === index + 1 ? 'none' : 'flex' }}
                    />
                </div>
            ))}
        </div>
    );
}
