import React, { useState } from "react";
import './WorkScreen.scss'
import TopBar from "../TopBar";
import FilesTable from "../FilesTable";
import { FileTableStatusBar } from "../FilesTable/FilesTable";
import { useStore } from "effector-react";
import { $browserTab, BrowserTabs } from "entities/browserTab";
import ImageLibrary from "components/Library/ImageLibrary";

export default function WorkScreen() {
    const [tableState, setTableState] = useState<boolean>(true)
    const browserTab = useStore($browserTab)
    return (

        <div className="work__screen">
            {/* <div className="temp"> */}
            <TopBar />
            {browserTab===BrowserTabs.STORAGE &&  <FilesTable tableState={tableState} />}
            {browserTab===BrowserTabs.FAVOURITES &&  <FilesTable tableState={tableState} isFavourites={true}/>}
            {browserTab===BrowserTabs.IMAGES &&  <ImageLibrary/>}
            <FileTableStatusBar tableState={tableState} setTableState={setTableState}/>
            {/* </div> */}

        </div>
    )
}