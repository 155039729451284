import { $browserTab, BrowserTabs } from 'entities/browserTab';
import SettingsBar from './SettingsBar/SettingsBar';
import StatusBar from './StatusBar/StatusBar';
import Tabs from './Tabs';
import './TopBar.scss';
import { useStore } from 'effector-react';

export default function TopBar() {
    const browserTab = useStore($browserTab)
    return <div className="top-bar">
        <Tabs />
        <StatusBar />
        {(browserTab === BrowserTabs.STORAGE ||
            browserTab === BrowserTabs.FAVOURITES ||
            browserTab === BrowserTabs.TRASH
        ) && <SettingsBar />}


    </div>;
}