export function addFavouritesToStorage(id: string, key: string) {
  let fav: string[] | null = JSON.parse(
    localStorage.getItem(key) || "null"
  );
  if (fav) {
    if (Array.isArray(fav)) {
      if (fav.includes(id))
        fav = fav.filter((fileId) => fileId !== id);
      else fav.push(id);
    } else {
      fav = [id];
    }
  } else {
    fav = [id];
  }
  localStorage.setItem(key, JSON.stringify(fav));
}
