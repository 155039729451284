import { createEvent, createStore } from "effector";
import { TagsType } from "./files";

export const groupByArr = Object.keys(TagsType).map((key) => ({
    value: TagsType[key as keyof typeof TagsType],
    title: key.toLowerCase()
  }));

export const $groupBy = createStore<TagsType>(TagsType.NONE);
export const selectGroupBy = createEvent<TagsType>();

$groupBy 
    .on(selectGroupBy, (_, value)=>value);