import config from '../config';

export async function uploadFile(file: File): Promise<void> {
    const url = new URL(config.baseUrl + '/api-quarters/file/presigned-link');
    url.searchParams.append('originalname', file.name);

    const responseLink = await fetch(url, { credentials: 'include' });
    if (!responseLink.ok) throw new Error('Fetch error.');

    const data = await responseLink.json();
    if (data.error) throw new Error(data.result);

    const formData = new FormData();
    Object.entries(data.result.formData).forEach((entry) => formData.append(entry[0], entry[1] as string));
    formData.append('file', file);
    const response = await fetch(data.result.postURL, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) throw new Error('Выгрузка изображения завершилась неудачей.');
}

export enum FileType {
    IMAGE = 'IMAGE',
    DOCUMENT = 'DOCUMENT',
    DRAWING = 'DRAWING',
    SKETCHUP = 'SKETCHUP',
    MODEL = 'MODEL',
    URL = 'URL',
}

export type RawFileData = {
    extension: string;
    favorite: boolean;
    filename: string;
    folderId: string | null;
    function: string | null;
    lastModified: string;
    note: string;
    object: string | null;
    originalname: string;
    program: string | null;
    rectified: boolean;
    size: number;
    subject: string | null;
    type: FileType;
    userId: string;
};

export async function loadFiles(): Promise<Array<RawFileData>> {
    const response = await fetch(config.baseUrl + '/api-quarters/file/files', { credentials: 'include' });
    if (!response.ok) throw new Error(response.statusText);

    const data = await response.json();
    if (data.error) throw new Error(data.result);
    return data.result;
}
