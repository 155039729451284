import React from "react";
import './Switcher.scss'
import classNames from "classnames";

interface SwitcherProps {
    value: boolean;
    onClick: () => void;
    name: string;
};

export default function Switcher({ value, onClick, name  }: SwitcherProps) {
    return (
        <div className="tags_swicher_wrapper" onClick={onClick}>
            <div className={classNames({ switcher: true, switcher_active: value })}>
                <div className={classNames({ switcher__stick: true, switcher__stick_active: value })} />
            </div>
            <p>{name}</p>
        </div>

    );
}