import { ReactNode, useState } from "react";
import './PreviewUnit.scss'

type PreviewUnitProps = {
    children: ReactNode;
    initialState: boolean;
    name: string;
}

function PreviewUnit({ children, initialState, name }: PreviewUnitProps) {
    const [isOpen, setIsOpen] = useState<boolean>(initialState)
    return (
        <>
            <div className="preview_unit" onClick={() => setIsOpen((prev) => !prev)}>
                <p>{name}</p>
                <div className={`preview_unit-arrow  ${isOpen && 'open'}`} />

            </div>

            {isOpen &&
                <div className="preview_unit-info">
                    {children}
                </div>}
        </>
    )
}

export default PreviewUnit;