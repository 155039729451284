import React, { useState } from "react";
import './Tag.scss'
import useClickOutside from "../../../services/useClickOutside";
import classNames from "classnames";
import { TagsType, addTag, removeTag, updateTag } from "entities/files";
import { TagEditMenu } from "../EditMenu/EditMenu";

interface TagProps {
    tag?: string,
    fileId: string,
    tagType: TagsType,
    index?: number,
    isEdit?: boolean,
    onEditEnd?: (state: boolean) => void;
}

function Tag({ tag, fileId, tagType, index, isEdit, onEditEnd }: TagProps) {
    const [newTag, setNewTag] = useState<string>(tag ? tag : '')
    const [isSelected, setIsSelected] = useState<boolean>(false)
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [isDoubleClick, setISDoubleClick] = useState<boolean>(isEdit ? isEdit : false)
    const [isInputEmpty, setIsInputEmpty] = useState(isEdit ? true : false);
    const tagRef = useClickOutside(() => {
        setISDoubleClick(false);
        setIsSelected(false);
        if (onEditEnd) onEditEnd(false);
        // if (newTag !== tag) {
        //     updateTag({ id: fileId, fieldName: tagType, value: newTag })
        // }
    });


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setNewTag(value);
        setIsInputEmpty(value.trim() === '');
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key !== 'Enter') return;
        setISDoubleClick(false);
        if (isEdit && onEditEnd) {
            if (!isInputEmpty) {
                addTag({ id: fileId, fieldName: tagType, value: newTag })
            }
            onEditEnd(false);
        } else if (newTag !== tag) {
            updateTag({ id: fileId, fieldName: tagType, value: newTag, index: index })
        }
    }

    const handleEditTag = () => {
        setIsSelected(false)
        setISDoubleClick(true);
    }


    const handleRemoveTag = (id: string, fieldName: TagsType, index?: number) => {
        removeTag({ id: id, fieldName: fieldName, index: index })
    }

    const handleCopyTag = (tagToCopy: string | undefined) => {
        if (tagToCopy) navigator.clipboard.writeText(tagToCopy)
    }

    const handlePasteTag = async (fileId: string, tagType: TagsType, index?: number) => {
        try {
            const text = await navigator.clipboard.readText();
            if (text.trim() !== '') {
                updateTag({ id: fileId, fieldName: tagType, value: text, index: index })
                navigator.clipboard.writeText('')
            }
        } catch (error) {
            console.error('Clipboard Error', error);
        }
    }



    return (
        <div
            className={classNames({
                'file_row-tag': true,
                'file_roe-tag-empty': isInputEmpty,
                'file_row-tag-selected': isSelected && !isDoubleClick,
                'file_row-tag-hovered': isHovered && !isSelected,
                'file_row-tag-edited': isDoubleClick && !isInputEmpty,
            })}

            ref={tagRef}
            onDoubleClick={() => handleEditTag()}
            onClick={() => setIsSelected(true)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>

            {isSelected &&
                <TagEditMenu
                    onCopyClick={() => handleCopyTag(tag)}
                    onEditClick={() => handleEditTag()}
                    onPasteClick={() => handlePasteTag(fileId, tagType, index)}
                    onDeleteClick={() => handleRemoveTag(fileId, tagType, index)} />
            }
            {isDoubleClick ?
                <input className="tag-input"
                    value={newTag}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    autoFocus={true} /> :
                <>
                    <p>{tag}</p>
                    <div className="file_row-tag_remove-icon" onClick={() => handleRemoveTag(fileId, tagType, index)} />
                </>
            }
        </div>
    )
}

export default Tag

