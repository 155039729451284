import { useStore } from "effector-react";
import { $files } from "entities/files";

import { useState, useEffect } from "react";

export default function useIsFileSelected(fileId: string): boolean {
  const files = useStore($files);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsSelected(files.selectedFilesID.includes(fileId));
  }, [fileId, files.selectedFilesID]);

  return isSelected;
}
