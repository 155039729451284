import React, { useMemo, useState } from "react";
import './SettingsBar.scss'
import classNames from "classnames";
import { useStore } from "effector-react";
import { $groupBy, groupByArr, selectGroupBy } from "../../../entities/groupBy";
import useClickOutside from "../../../services/useClickOutside";
import { $AIMode, $files, $tagsMode, TagsType, approveAllAITags, generateAITags, selectAllFiles, toggleAIMode, toggleTagsMode } from "entities/files";
import Switcher from "components/Switcher";

export default function SettingsBar() {
    const tagsMode = useStore($tagsMode);
    const AIMode = useStore($AIMode)
    const { activeFiles: files, selectedFilesID } = useStore($files)
    const allNotSelected = useMemo(() => selectedFilesID.length === 0 && files.length !== 0, [files, selectedFilesID]);

    return (
        <div className="settings__bar">
            <div className="settings__bar_left_part">
                {AIMode && <div className="settings_ai_tools">
                    <div className="settings_ai_tools-item" onClick={() => generateAITags(true)}>
                        <div className="settings_ai_tools-retry_icon" />
                        Retry
                    </div>
                    <div className="settings_ai_tools-item" onClick={() => approveAllAITags()}>
                        <div className="settings_ai_tools-approve_icon" />
                        Approve all AI Tags
                    </div>
                </div>}
                <button onClick={() => {
                    if (allNotSelected) selectAllFiles(true)
                    toggleAIMode();
                    toggleTagsMode(true)
                }}
                    className={classNames({ 'settings__bttn': true })}>
                    <div className="settings_star_icon" />
                    {AIMode ? 'Disable AI Tags' : 'Enable AI Tags'}

                </button>
                {!AIMode && <Switcher name={'Tags'} value={tagsMode} onClick={() => toggleTagsMode()} />}
            </div>
            <GroupDropdown />
        </div >
    )
}


// type SwitcherProps = {
//     value: boolean;
//     onClick: () => void;
// };

// function Switcher({ value, onClick }: SwitcherProps) {
//     return (
//         <div className="tags_swicher_wrapper" onClick={onClick}>
//             <div className={classNames({ switcher: true, switcher_active: value })}>
//                 <div className={classNames({ switcher__stick: true, switcher__stick_active: value })} />
//             </div>
//             <p>Tags</p>
//         </div>

//     );
// }

function GroupDropdown() {

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const groupBy = useStore($groupBy);
    const dropdownRef = useClickOutside(() => {
        setIsMenuOpen(false);
    });

    return (
        <>
            <div className="settings_group_dropdown" ref={dropdownRef} onClick={() => setIsMenuOpen((prev) => !prev)}>
                <div className="settings_group_dropdown-icon " />
                <p>Group by &quot;{groupBy.toLowerCase()}&quot;</p>
                <div className={`settings_group_dropdown-arrow ${isMenuOpen && 'inactive'}`} />
                <ul className={`settings_group_dropdown-menu`}
                    style={{ display: isMenuOpen ? 'flex' : 'none' }}>
                    {groupByArr.map((item) => (
                        <li key={item.value} onClick={() => selectGroupBy(item.value)}>
                            {item.title}
                            {(item.value === groupBy) &&
                                <div className="selected_arrow" />}
                        </li>
                    ))}
                    <div onClick={() => selectGroupBy(TagsType.NONE)} className={`settings_group_dropdown-ungroup ${groupBy === TagsType.NONE ? 'disabled' : 'active'}`}> Ungroup</div>
                </ul>
            </div >

        </>
    )
}

