

import { useEffect, useRef } from "react";
import Filter from "./Filter";
import './LeftBar.scss';
import Browser from "./Browser";

export default function LeftBar() {
    const leftBarRef = useRef<HTMLDivElement>(null)
    const browserRef = useRef<HTMLDivElement>(null)
    const refRight = useRef<HTMLDivElement>(null)
    const refBottom = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const resizerRight = refRight.current;
        const resizerBottom = refBottom.current;

        if ((leftBarRef && leftBarRef.current && resizerRight) &&
            (browserRef && browserRef.current && resizerBottom)) {

            const resizeableLeftbar = leftBarRef.current;
            const leftbarStyles = window.getComputedStyle(resizeableLeftbar)
            let width = parseInt(leftbarStyles.width, 10)
            let x = 0;

            const resizeableBrowser = browserRef.current;
            const browserStyles = window.getComputedStyle(resizeableBrowser)
            let height = parseInt(browserStyles.height, 10);
            let y = 0;

            const onMouseMoveRightResize = (event: MouseEvent) => {
                const dx = event.clientX - x;
                x = event.clientX;
                width = width + dx;
                resizeableLeftbar.style.width = `${width}px`;
            };

            const onMouseUpRightResize = () => {
                document.removeEventListener("mousemove", onMouseMoveRightResize);
            };

            const onMouseDownRightResize = (event: MouseEvent) => {
                x = event.clientX;
                resizeableLeftbar.style.left = leftbarStyles.left;
                document.addEventListener("mousemove", onMouseMoveRightResize);
                document.addEventListener("mouseup", onMouseUpRightResize);
            };

            const onMouseMoveBottomResize = (event: MouseEvent) => {
                const dy = event.clientY - y;
                height = height + dy;
                y = event.clientY;
                resizeableBrowser.style.height = `${height}px`;
            };

            const onMouseUpBottomResize = () => {
                document.removeEventListener("mousemove", onMouseMoveBottomResize);
            };

            //TO DO 
            const onMouseDownBottomResize = (event: MouseEvent) => {
                y = event.clientY;
                console.log(y)
                const styles = window.getComputedStyle(resizeableBrowser);
                resizeableBrowser.style.top = styles.top;
                document.addEventListener("mousemove", onMouseMoveBottomResize);
                document.addEventListener("mouseup", onMouseUpBottomResize);
            };

            resizerRight.addEventListener("mousedown", onMouseDownRightResize);
            resizerBottom.addEventListener("mousedown", onMouseDownBottomResize);

            return () => {
                resizerRight.removeEventListener("mousedown", onMouseDownRightResize);
                resizerBottom.removeEventListener("mousedown", onMouseDownBottomResize);
            };
        }
    })


    // useEffect(() => {
    //     const resizerRight = refRight.current;
    //     const resizerBottom = refBottom.current;



    //     //bottom resize
    //     if (browserRef && browserRef.current && resizerBottom) {
    //         const resizeableElement = browserRef.current;
    //         const styles = window.getComputedStyle(resizeableElement)
    //         let height = parseInt(styles.height, 10);
    //         let y = 0;

    //         resizeableElement.style.top = "0px";
    //         resizeableElement.style.left = "0px";

    //         const onMouseMoveBottomResize = (event: MouseEvent) => {
    //             const dy = event.clientY - y;
    //             height = height + dy;
    //             y = event.clientY;
    //             resizeableElement.style.height = `${height}px`;
    //         };

    //         const onMouseUpBottomResize = (event: MouseEvent) => {
    //             document.removeEventListener("mousemove", onMouseMoveBottomResize);
    //         };

    //         const onMouseDownBottomResize = (event: MouseEvent) => {
    //             y = event.clientY;
    //             const styles = window.getComputedStyle(resizeableElement);
    //             console.log(styles.top)
    //             resizeableElement.style.top = styles.top;
    //             document.addEventListener("mousemove", onMouseMoveBottomResize);
    //             document.addEventListener("mouseup", onMouseUpBottomResize);
    //         };

    //         resizerBottom.addEventListener("mousedown", onMouseDownBottomResize);

    //         return () => {
    //             resizerBottom.removeEventListener("mousedown", onMouseDownBottomResize);
    //         };

    //     }
    // })


    return (
        <div className="left-bar" ref={leftBarRef}>
            <div className="resizer" ref={refRight}>
                <div className="resizer-r" />
            </div>
            <div className="top-block"
                ref={browserRef}>
                <Browser />
                <div className="resizer_browser" 
                ref={refBottom}>
                    <div className="resizer-b" />
                </div>
            </div>
            <Filter />
        </div>
    );
}