import React, { useState } from 'react';
import './Tabs.scss';
import classNames from 'classnames';


export interface TabI {
    title: string,
    url: string,
}
const tabs : TabI[]= [
    {
        title: 'Upload',
        url: '/storage/0'
    },
    {
        title: 'Image library',
        url: '/library/images'
    },
    {
        title: 'Folder name 04',
        url: '/storage/04'
    },
]

export default function Tabs() {
    const [activeTab, setActiveTab] = useState<string>('Upload')

    return (
        <div className="tabs">
            <div className="tabs__home-button" />
            {tabs.map((tab, index) => (
                <Tab isActive={tab.title===activeTab} 
                key={`tab_${index}`}
                tab={tab}
                setActiveTab={setActiveTab}/>
            ))}
            <div className="tabs__new-tab-button"></div>
        </div>
    );
}

function Tab({ isActive, tab, setActiveTab }: { isActive?: boolean; tab: TabI; setActiveTab: (title: string) => void }) {
    return (
      <div
        className={classNames({ tab: true, 'tab_active': isActive })}
        onClick={() => setActiveTab(tab.title)}
      >
        <div className="tab__icon" />
        <div className="tab__name">{tab.title}</div>
        <div className="tab__close-button" />
      </div>
    );
  }