import React, { useCallback } from "react"
import './FileCheckbox.scss'
import useIsFileSelected from "services/useIsFileSelected"
import { $AIMode, generateFileAITags, removeFileAITags, selectFile } from "entities/files"
import { useStore } from "effector-react"

function FileCheckbox({ fileId }: { fileId: string }) {
    const AIMode = useStore($AIMode)
    const isSelected = useIsFileSelected(fileId)

    const handleCheckboxClick = useCallback(() => {
        selectFile(fileId)
        if (AIMode) {
            if (!isSelected) return generateFileAITags(fileId)
            removeFileAITags(fileId)
        }

    }, [AIMode, fileId, isSelected])

    return (
        <div className="file_checkbox" onClick={() => handleCheckboxClick()}>
            {isSelected && <div className="file_checkbox-square-active" />}
        </div>
    )
}


export default FileCheckbox