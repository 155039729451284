import classNames from 'classnames';
import './Textarea.scss';


type TextareaProps = {
    placeholder?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: () => void;
    className?: string;
    disabled?: boolean;
};

export default function Textarea({ placeholder='Enter a note', value, onChange, onBlur, className = '', disabled = false }: TextareaProps) {
    return (
        <textarea
            className={classNames({
                textarea: true,
                [className]: Boolean(className),
            })}
            rows={2}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={onBlur}
        />
    );
}