import React, { useEffect, useState } from "react";
import './ImageLibrary.scss'
import { mockAItags } from "components/FilesTable/config";
import { TagsType } from "entities/files";

export default function ImageLibrary() {
    return (
        <div className="library_image-wrapper">
            <div className="library_image">
                <div className="show_all-wrapper">
                    <p className="category_title">Show all</p>
                </div>
                <NavigateBy type={TagsType.CATEGORY} />
                <NavigateBy type={TagsType.OBJECT} />
                <NavigateBy type={TagsType.SUBJECT} />
                <NavigateBy type={TagsType.PROGRAM} />
                <NavigateBy type={TagsType.APPLICATION} />
            </div>
        </div>
    )
}

const categoryItems = ['Photo', 'Collage', 'Rendering', 'Model']

function NavigateBy({ type }: { type: TagsType }) {
    const [navigateItems, setNavigateItems] = useState<string[]>()

    useEffect(() => {
        setNavigateItems(type === TagsType.CATEGORY ? categoryItems : mockAItags.find((item) => item.type === type)?.items);
    }, [type])

    return (
        <div className="navigate_by-item">
            <div className="navigate_by-item-info">
                <p>Navigate by <span className="navigate_by_type">{type.toLocaleLowerCase()}</span></p>
            </div>
            <div className="navigate_items-wrapper">

                <div className="navigate_item">
                    <div className="navigate_preview" />
                    <div className="navigate_preview_fav">
                        <div className="navigate_preview_fav_icon "/>
                        <p className="category_title">Favourites</p>
                    </div>
                </div>
                {navigateItems?.map((navigateItem, index) => (
                    <div className="navigate_item">
                        <div className="navigate_preview" key={`library_images_${navigateItem}_${index}`} />
                        <p className="category_title">{navigateItem}</p>
                    </div>
                ))}

            </div>
        </div>
    )

}