import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import useClickOutside from '../../../services/useClickOutside';
import { $AIMode, $tagsMode, $userId, FileData, TagsType, addFiletoFavourites, addFoldertoFavourites, setSelectedFileID } from '../../../entities';
import Tag from "../Tag";
import TagSelect from "../TagSelect";
import AITag from "../AITag";
import FileCheckbox from "../FileCheckbox";
import { FolderData, setActiveFolderID } from 'entities/folders';
import useIsFavourite from 'services/useIsFavourite';
import config from 'config';
import { BrowserTabs, switchTab } from 'entities/browserTab';
import './FileRow.scss';


interface FileRowProps {
    file: FileData;
    index: number;
}

export default function FileRow({ file, index }: FileRowProps) {
    const userId = useStore($userId);
    const isFavourite = useIsFavourite(file.filename, 'favoritedFilesID');
    const [isSettingsOpen, setIsSettigsOpen] = useState<boolean>(false);
    const settingsRef = useClickOutside(() => setIsSettigsOpen(false));
    const tagsMode = useStore($tagsMode);
    const AIMode = useStore($AIMode);

    return (
        <Draggable
            draggableId={file.filename}
            key={file.filename}
            index={index}>
            {(provided, snapshot) => (
                <div {...provided.draggableProps} ref={provided.innerRef}>
                    <div className={classNames({
                        'file_row': !tagsMode,
                        'file_row_is-being-dragged': snapshot.isDragging,
                        "file_row-tagsMode": tagsMode || AIMode,
                    })}
                        id={'file_' + file.filename}
                        onClick={() => setSelectedFileID(file.filename)}>

                        <div  {...provided.dragHandleProps}>
                            <div className="file_row__drag" />
                        </div>
                        <FileCheckbox fileId={file.filename} />
                        <div className='file_row__preview'>
                            <div className="file_row__preview-image">
                                <img src={file.preview} alt=" " />
                            </div>
                            <p>{file.originalname}</p>
                        </div>
                        {tagsMode || AIMode ? (
                            <TagsElement file={file} />
                        ) : (
                            <>
                                <div className="file_row_wrapper">
                                    <div className="file_row__date">{file.date}</div>
                                </div>

                                <div className="file_row_wrapper">
                                    <div className="file_row__size">{file.size}</div>
                                </div>
                                <div className="file_row_wrapper">
                                    <div className="file_row__access">
                                        <div className="file_row__access-image_wrapper">
                                            {file.access.map((_, index) => (
                                                <div
                                                    className="file_row__access-image"
                                                    key={`avatar_${file.filename}_${index}`}
                                                    style={{ left: index * 15 }}
                                                >
                                                    <img src={`${config.minioUrl}/backup/${userId}/${file.filename}`} alt={file.extension} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={classNames({ 'file_row__fav': true, 'file_row__fav_active': isFavourite })}
                            onClick={() => addFiletoFavourites(file.filename)}
                        />
                        <div className='file_row__settings'
                            ref={settingsRef}
                            onClick={() => setIsSettigsOpen((prev) => !prev)}>
                            <div className="file_row__settings_menu"
                                style={{ display: isSettingsOpen ? 'flex' : 'none' }}>
                                <div className="file_row__settings_menu-item">Delete</div>
                                <div className="file_row__settings_menu-item">Something</div>
                            </div>
                        </div>

                    </div>
                </div>
            )
            }
        </Draggable>
    )
}




function TagsElement({ file }: { file: FileData }) {
    const AIMode = useStore($AIMode)
    const [addSubjectTag, setAddSubjectTag] = useState<boolean>(false)
    const [addApplicationTag, setAddApplicationTag] = useState<boolean>(false)
    return (
        <>
            <div className="file_row__tag-cell-wrapper">
                <div className="file_row__tag-wrapper ">
                    <div className="file_row__tag-cell">
                        {file.AItags && file.AItags.category ? (
                            <AITag tag={file.AItags.category} key={`ai-${file.AItags.category}`} fileId={file.filename} tagType={TagsType.CATEGORY} />
                        ) : (
                            <TagSelect tag={file.tags.category} key={`tag-${file.tags.category}}`} fileId={file.filename} />)
                        }
                    </div>
                </div>
            </div>
            <div className="file_row__tag-cell-wrapper">

                <div className="file_row__tag-wrapper ">
                    <div className="file_row__tag-cell" >
                        {file.AItags && file.AItags.object ? (
                            file.AItags && file.AItags.object &&
                            <AITag tag={file.AItags.object} key={`ai-${file.AItags.object}`} fileId={file.filename} tagType={TagsType.OBJECT} />

                        ) : (
                            file.tags && file.tags.object &&
                            <Tag tag={file.tags.object} key={`tag-${file.tags.object}`} fileId={file.filename} tagType={TagsType.OBJECT} />)
                        }

                    </div>
                </div>
            </div>
            <div className="file_row__tag-cell-wrapper">
                <div className="file_row__tag-wrapper ">
                    <div className="file_row__tag-cell">
                        {file.tags && file.tags.subject && file.tags.subject.length !== 0 &&
                            <>
                                {file.tags.subject.map((item, index) => (
                                    <Tag tag={item} key={`tag-${item}-${index}`} fileId={file.filename} tagType={TagsType.SUBJECT} index={index} />
                                ))}
                                {addSubjectTag && <Tag fileId={file.filename} tagType={TagsType.SUBJECT} isEdit={true} onEditEnd={setAddSubjectTag} />}
                                {!AIMode && <div className="file_row__add-multiple-tags-icon" onClick={() => setAddSubjectTag(true)} />}
                            </>
                        }
                        {AIMode &&
                            file.AItags && file.AItags.subject && file.AItags.subject.length !== 0 &&
                            file.AItags.subject.map((item, index) => (
                                <AITag tag={item} key={`ai-${item}-${index}`} fileId={file.filename} tagType={TagsType.SUBJECT} index={index} />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="file_row__tag-cell-wrapper">
                <div className="file_row__tag-wrapper ">
                    <div className="file_row__tag-cell">
                        {file.AItags && file.AItags.program ? (
                            file.AItags && file.AItags.program &&
                            <AITag tag={file.AItags.program} key={`ai-${file.AItags.program}`} fileId={file.filename} tagType={TagsType.PROGRAM} />
                        ) : (
                            file.tags && file.tags.program &&
                            <Tag tag={file.tags.program} key={`tag-${file.tags.program}`} fileId={file.filename} tagType={TagsType.PROGRAM} />)
                        }

                    </div>
                </div>
            </div>
            <div className="file_row__tag-cell-wrapper">
                <div className="file_row__tag-wrapper ">
                    <div className="file_row__tag-cell">
                        {file.tags && file.tags.application && file.tags.application.length !== 0 &&
                            <>
                                {file.tags.application.map((item, index) => (
                                    <Tag tag={item} key={`tag-${item}-${index}`} fileId={file.filename} tagType={TagsType.APPLICATION} index={index} />
                                ))}
                                {addApplicationTag && <Tag fileId={file.filename} tagType={TagsType.APPLICATION} isEdit={true} onEditEnd={setAddApplicationTag} />}
                                {!AIMode && <div className="file_row__add-multiple-tags-icon" onClick={() => setAddApplicationTag(true)} />}
                            </>
                        }
                        {AIMode &&
                            file.AItags && file.AItags.application && file.AItags.application.length !== 0 &&
                            file.AItags.application.map((item, index) => (
                                <AITag tag={item} key={`ai-${item}-${index}`} fileId={file.filename} tagType={TagsType.APPLICATION} index={index} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export function FolderRow({ folder }: { folder: FolderData }) {
    const isFavourite = useIsFavourite(folder.id, 'favoritedFoldersID')
    const tagsMode = useStore($tagsMode)
    const AIMode = useStore($AIMode)

    const [isSettingsOpen, setIsSettigsOpen] = useState<boolean>(false)
    const settingsRef = useClickOutside(() => {
        setIsSettigsOpen(false);
    });


    const addHistory = (folderID: string) => {
        const newPath = `/storage/${folderID}`
        window.history.pushState(null, '', newPath);
        setActiveFolderID(folderID);
        switchTab(BrowserTabs.STORAGE);
    }
    return (

        <div className={classNames({
            'file_row': !tagsMode,
            "file_row-tagsMode": tagsMode || AIMode,
        })}
            id={'folder_' + folder.id}
            onDoubleClick={() => addHistory(folder.id)}
        >
            <div>
                <div className="file_row__drag" />
            </div>
            <FileCheckbox fileId={folder.id} />

            <div className='file_row__preview'>
                <div className="folder-row-icon" />
                <p>{folder.name}</p>
            </div>

            {tagsMode || AIMode ? (
                <>
                    <div className="file_row__tag-cell-wrapper" />
                    <div className="file_row__tag-cell-wrapper" />
                    <div className="file_row__tag-cell-wrapper" />
                    <div className="file_row__tag-cell-wrapper" />
                    <div className="file_row__tag-cell-wrapper" />
                </>
            ) : (
                <>
                    <div className="file_row_wrapper">
                        <div className="file_row__date">{folder.date}</div>
                    </div>

                    <div className="file_row_wrapper">
                        <div className="file_row__size">{folder.size}</div>
                    </div>
                    <div className="file_row_wrapper">
                        <div className="file_row__access">
                            <div className="file_row__access-image_wrapper">
                                {folder.acess.map((item, index) => (
                                    <div className="file_row__access-image"
                                        key={`avatar_${folder.id}_${index}`}
                                        style={{ left: index * 15 }}>
                                        <img src={item.avatar} alt=" " />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className={classNames({ 'file_row__fav': true, 'file_row__fav_active': isFavourite })}
                onClick={() => addFoldertoFavourites(folder.id)} />
            <div className='file_row__settings'
                ref={settingsRef}
                onClick={() => setIsSettigsOpen((prev) => !prev)}>
                <div className="file_row__settings_menu"
                    style={{ display: isSettingsOpen ? 'flex' : 'none' }}>
                    <div className="file_row__settings_menu-item">Delete</div>
                    <div className="file_row__settings_menu-item">Something</div>
                </div>
            </div>
        </div>

    )

}
